const UserAccountPassengersEmpty = () => {
    return (
        <div className="user-table-empty">
          <img
            className="user-table-empty-image"
            src="/image/img/notfound_img.svg"
            alt="notfound"
          />
          <h2 className="user-table-empty-title">У Вас ще немає пасажирів</h2>
        </div>
    )
}

export default UserAccountPassengersEmpty;