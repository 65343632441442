import Step from "../../components/Step/Step.jsx";
import OrderSuccess from "../../components/OrderSuccess/OrderSuccess.jsx";

const Booking = () => {
  const type = "final";
  return (
    <>
      <div className="page-head">
        <Step type={type} />
      </div>
      <div className="page-container">
        <OrderSuccess />
      </div>
    </>
  );
};

export default Booking;
