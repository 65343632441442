import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";

export const UserAccountNav = () => {
  const [ordreGreen, setOrdreGreen] = useState("");
  const [passengersGreen, setPassengersGreen] = useState("");
  const [settingsGreen, setSettingsGreen] = useState("");
  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    setOrdreGreen("");
    setPassengersGreen("");
    setSettingsGreen("");
    switch (path) {
      case "/user-account/orders":
        setOrdreGreen("line-green");
        break;
      case "/user-account/passengers":
        setPassengersGreen("line-green");
        break;
      case "/user-account/settings":
        setSettingsGreen("line-green");
        break;
    }
  }, [path]);

  return (
    <>
      <div className="desctop-menu">
        <nav className="nav-menu">
          <ul className="list-nav-menu">
            <li className="item-1">
              <Link
                to="/user-account/orders"
                className={`text-navigation-1 ${ordreGreen}`}
                onClick={() => setPath("/user-account/orders")}
              >
                Замовлення
              </Link>
            </li>
            <li className="item">
              <Link
                to="/user-account/passengers"
                className={`text-navigation-1 ${passengersGreen}`}
                onClick={() => setPath("/user-account/passengers")}
              >
                Мої пасажири
              </Link>
            </li>
            <li className="item">
              <Link
                to="/user-account/settings"
                className={`text-navigation-1 ${settingsGreen}`}
                onClick={() => setPath("/user-account/settings")}
              >
                Налаштування
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default UserAccountNav;
