import { Link } from "react-router-dom";
import { ROUTES } from "../../pages/utils/routes";

export const Footer = () => {
  const handleNavLinkClick = () => {
    window.scrollTo(0, 0); // Прокручиваем страницу вверх при переходе по ссылке
  };

  return (
    <>
      <footer className="footer-container">
        <div className="footer">
          <div className="footer-chapter contacts">
            <span className="footer-chapter-title">Служба підтримки</span>
            <span className="footer-chapter-phone">
              <a className="footer-link" href="tel:+380973191904">
                +38 (097) 319-19-04
              </a>
            </span>
            <span className="footer-chapter-phone">
              <a className="footer-link" href="tel:+30637347229">
                +38 (063) 734-72-29
              </a>
            </span>
            <span className="footer-chapter-email">
              <Link className="footer-link" href="mailto:help@bus4trip.com.ua">
                help@bus4trip.com.ua
              </Link>
            </span>
            <div className="messangers">
              <img
                className="messanger-icon-mobile"
                src="/image/footer-icons/viber.svg"
              />
              <img
                className="messanger-icon-mobile"
                src="/image/footer-icons/telegram.svg"
              />
            </div>
          </div>
          <div className="navigation-copyright">
            <div className="navigation">
              <div className="footer-chapter menu-block-1">
                <span className="footer-chapter-title">Документи</span>
                <Link
                  className="footer-link"
                  to={ROUTES.OFFER}
                  onClick={handleNavLinkClick}
                >
                  <span className="footer-chapter-item">Договір оферти</span>
                </Link>
                <br />
                <Link
                  className="footer-link"
                  to={ROUTES.CONFIDENCE}
                  onClick={handleNavLinkClick}
                >
                  <span className="footer-chapter-item">
                    Політика конфіденційності
                  </span>
                </Link>
              </div>
              <div className="footer-chapter menu-block-2">
                <span className="footer-chapter-title">Партнерам</span>
                <Link
                  className="footer-link"
                  to={ROUTES.PARTNERS}
                  onClick={handleNavLinkClick}
                >
                  <span className="footer-chapter-item">Співпраця</span>
                </Link>
              </div>
            </div>
            <div className="acquiring-copyright">
              <div className="acquiring">
                <img
                  className="acquiring-logo"
                  src="/image/footer-icons/maestro.svg"
                />
                <img
                  className="acquiring-logo"
                  src="/image/footer-icons/mastercard.svg"
                />
                <img
                  className="acquiring-logo acquiring-logo-visa"
                  src="/image/footer-icons/visa.svg"
                />
              </div>
              <div className="copyright">
                bus4trip – твій автобус для подорожі © 2023
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
