import TicketItem from "../../components/TicketItem/TicketItem.js";
import { nanoid } from "nanoid";
import { Watch } from "react-loader-spinner";

export const TicketList = ({ tickets, search }) => {
  return (
    <>
      <div className="ticket-list">
        {tickets &&
          tickets.map((ticket, index) => {
            return (
              <TicketItem key={nanoid()} ticket={ticket} search={search} />
            );
          })}
      </div>
      {!tickets && (
        <div className="loaderRoot">
          <div className="loaderStyle">
            <Watch
              height="80"
              width="80"
              radius="48"
              color="#4fa94d"
              ariaLabel="watch-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default TicketList;
