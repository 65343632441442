import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import UserAccountOrdersEmpty from "./UserAccountOrdersEmpty";
import UserAccountOrdersTable from "./UserAccountOrdersTable";
import UserAccountOrdersTableHead from "./UserAccountOrdersTableHead";

// async function getUserBooking() {
//   let token = localStorage.getItem("token");
//   const domain = process.env.REACT_APP_DOMAIN;
//   const url = `${domain}/api/bus4trip/booking`;
//   return fetch(url, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//   }).then((data) => data.json());
// }

export const UserAccountOrders = () => {
  const { height, width } = useWindowDimensions();
  const [booking, setBooking] = useState();
  let tableHead = <UserAccountOrdersTableHead />;

  if (width < 525) {
    tableHead = "";
  }

  useEffect(() => {
    async function FetchData() {
      let token = localStorage.getItem("token");
      const domain = process.env.REACT_APP_DOMAIN;
      const url = `${domain}/api/bus4trip/booking`;
      return fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => data.json())
        .then((resutl) => {
          setBooking(resutl.bookings);
        });
    }
    if (booking === undefined) {
      FetchData();
    }
  }, [booking]);

  let tableContent = <UserAccountOrdersEmpty />;
  if (booking?.length) {
    tableContent = <UserAccountOrdersTable booking={booking} />;
  }

  return (
    <>
      <div className="user-menu-block">
        {tableHead}
        {tableContent}
      </div>
    </>
  );
};

export default UserAccountOrders;
