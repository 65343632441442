import React from "react";

const UserAccountOrdersTableItemDesktop = ({ item }) => {
  return (
    <div className="user-table-content-row">
      <div className="user-table-content-row-value">
        <div className="order-table-cell-order">{item?.id}</div>
        <div className="order-table-cell-from">{item?.city_from}</div>
        <div className="order-table-cell-to">{item?.city_to}</div>
        <div className="order-table-cell-date">{item?.create_date}</div>
        <div className="order-table-cell-total">{item?.phone}</div>
        <div className="order-table-cell-total">{item?.email}</div>
      </div>
    </div>
  );
};

export default UserAccountOrdersTableItemDesktop;
