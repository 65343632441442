import React, { useEffect, useState } from "react";
import TicketFilter from "../../components/TicketFilter/TicketFilter.js";
import TicketList from "../../components/TicketList/TicketList.js";
import useQueryParams from "../../hooks/useQueryParams.js";

export const Search = (newSearch) => {
  const search = useQueryParams();
  const [params, setParams] = useState("");
  const [tickets, setTickets] = useState();

  const currentQuery = `lang=${search["lang"]}&city-from=${search["city-from"]}&city-to=${search["city-to"]}&date=${search["date"]}&passengers=${search["passengers"]}`;
  if (params === "" || params !== currentQuery) {
    setParams(currentQuery);
  }

  useEffect(() => {
    const token = process.env.REACT_APP_API_TOKEN;
    const domain = process.env.REACT_APP_DOMAIN;
    const url = `${domain}/api/bus4trip/search?${params}`;

    async function FetchData() {
      fetch(url)
        .then((res) => res.json())
        .then((result) => {
          if (result && result["tickets"]) {
            setTickets(result["tickets"]);
          }
        });
    }
    FetchData();
  }, [params]);

  return (
    <>
      <TicketFilter />
      <TicketList tickets={tickets} search={search} />
    </>
  );
};

export default Search;
