const UserAccountPassengersTableHead = () => {
  return (
    <div className="user-table-head">
      <div className="user-table-head-column">
        <span className="user-table-head-column-title passenger-table-column-username">
          Ім’я / Прізвище
        </span>
        <span className="user-table-head-column-title passenger-table-column-birth-date">
          Дата народження
        </span>
        {/* <span className="user-table-head-column-title passenger-table-column-phone">
          Телефон
        </span> */}
        <span className="user-table-head-column-title passenger-table-column-email">
          Email
        </span>
        <span className="user-table-head-column-title passenger-table-column-doc-number">
          Статус
        </span>
      </div>
    </div>
  );
};

export default UserAccountPassengersTableHead;
