import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { ROUTES } from "../../utils/routes";
import "../Auth.css";
import Auth from "../../../layout/Auth/Auth.js";

async function registerUser(credentials) {
  const token = process.env.REACT_APP_API_TOKEN;
  const domain = process.env.REACT_APP_DOMAIN;
  const url = `${domain}/api/auth/register`;
  // Зробити запит на реєстрацію користувача
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export const Register = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("+380");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== password_confirmation) {
      swal("Failed", "Паролі не співпадають");
    } else {
      const response = await registerUser({
        email,
        phone,
        password,
        password_confirmation,
      });

      if ("phone" in response) {
        // swal(
        //   "Ми надіслали вам e-mail для активації аккаунта",
        //   "Перейдіть за посиланням, щоб підтвердити адресу",
        //   "success",
        //   {
        //     buttons: false,
        //     timer: 20000,
        //   }
        // ).then((value) => {
        localStorage.setItem("phone", response["phone"]);
        navigate("/verify/phone");
        // });
      } else {
        // swal("Failed", response.message, "error");
        swal("Failed", "Номер телефону не підходить", "error");
      }
    }
  };

  const changePhone = (event) => {
    const regex = /[^\d+()\-\s]/g;
    const inputValue = event.target.value;
    const cleanedValue = inputValue.replace(regex, "");

    setPhone(cleanedValue);
  };

  return (
    <>
      <PageTitle title={"Реєстрація"} />
      <Auth>
        <form className="auth-form" onSubmit={handleSubmit}>
          <div className="user-input-wrp">
            <br />
            <input
              required
              id="email"
              name="email"
              label="Email Address"
              className="inputText"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="off"
            />
            <span className="floating-label">Електронна пошта</span>
          </div>
          <div className="user-input-wrp">
            <br />
            <input
              required
              id="phone"
              name="phone"
              label="Phone Number"
              className="inputText"
              type="tel"
              value={phone}
              onChange={changePhone}
              autoComplete="off"
            />
            <span className="floating-label">Номер телефону</span>
          </div>
          <div className="user-input-wrp">
            <br />
            <input
              required
              id="password"
              name="password"
              label="Password"
              type="password"
              className="inputText"
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="off"
            />
            <span className="floating-label">Пароль</span>
          </div>
          <div className="user-input-wrp">
            <br />
            <input
              required
              id="password_confirmation"
              name="password_confirmation"
              label="Confirm Password"
              type="password"
              className="inputText"
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              autoComplete="off"
            />
            <span className="floating-label">Підтвердження пароля</span>
          </div>
          <button className="auth-submit" type="submit">
            Продовжити
          </button>
          <div className="other-options">
            <div className="register-account">
              <span className="register-account-title">Маєш акаунту?</span>
            </div>
            <Link className="register-account-link" to={ROUTES.LOGIN}>
              Перейти до входу
            </Link>
          </div>
        </form>
      </Auth>
    </>
  );
};

export default Register;
