const FinalPoint = ({ name, time, address }) => {
  return (
    <>
      <div className="ticket-info-full-road-map-point-item">
        <div className="ticket-info-full-road-map-chapter ticket-info-full-road-map-chapter-finish">
          <span>Прибуття</span>
          <span>(місцевий час)</span>
        </div>
        <div>
          <span className="ticket-info-full-road-map-point-image">
            <img
              className="ticket-info-full-road-map-point-image-dot"
              src="./image/svg/point-dot.svg"
              alt=""
            />
          </span>
        </div>
        <div className="ticket-info-full-road-map-point-detail">
          <span className="point-time">{time}</span>
          <span className="point-time">{address}</span>
          <span className="point-title">{name}</span>
        </div>
      </div>
    </>
  );
};

export default FinalPoint;
