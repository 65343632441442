import { useState } from "react";

const BusSchemeSeat = ({ seat, chooseSeats, handleChooseSeat }) => {
  const [booking, setBooking] = useState(false);
  
  let status = {};
  if (chooseSeats.filter((item) => item.id === seat.id).length === 1) {
    status = {
      background: "#31CA57",
      color: "#FFFFFF",
    };
  }

  const handleClick = (id, num, tariffs, status) => {
    if (status === 1)
      handleChooseSeat(id, num, tariffs);
  };

  return (
    <>
      <div
        className={seat.status === 1 ? "seats-item seats-free" : 'seats-item'}
        style={status}
        onClick={() => handleClick(seat.id, seat.num, seat.tariffs, seat.status)}
      >
        <span className="seats-item-title">{seat.num}</span>
      </div>
    </>
  );
};

export default BusSchemeSeat;
