
const UserAccountOrdersTableHead = () => {
    return (
        <div className="user-table-head">
          <div className="user-table-head-column">
            <span className="user-table-head-column-title order-table-column-order">
              № замовлення
            </span>
            <span className="user-table-head-column-title order-table-column-from">
              Звідки
            </span>
            <span className="user-table-head-column-title order-table-column-to">
              Куди
            </span>
            <span className="user-table-head-column-title order-table-column-date">
              Дата замовлення
            </span>
            <span className="user-table-head-column-title order-table-column-total">
              Телефон
            </span>
            <span className="user-table-head-column-title order-table-column-status">
              Email
            </span>
          </div>
        </div>
    );
  };
  
  export default UserAccountOrdersTableHead;
  