import React, { useEffect, useState } from "react";
import "./Timer.css";
import { getPadTime } from "../../helpers/getPadTime";

const Timer = ({ticket}) => {
  const waitTime = 0 * 60;
  const [timeLeft, setTimeLeft] = useState(waitTime);
  const [isCounting, setIsCounting] = useState(true);
  const minutes = getPadTime(Math.floor(timeLeft / 60));
  const seconds = getPadTime(timeLeft - minutes * 60);

  useEffect(() => {
    if (ticket?.cancel_time) {
      setTimeLeft(ticket.cancel_time * 60)
    }
  }, [ticket])

  useEffect(() => {
    const interval = setInterval(() => {
      isCounting &&
        setTimeLeft((timeLeft) => (timeLeft >= 1 ? timeLeft - 1 : 0));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [isCounting]);

  return (
    <>
      <div className="checkout-timer-block">
        <div className="checkout-timer-instruction">
          <img
            className="checkout-timer-image"
            src="/image/svg/timer.svg"
            alt="timer"
          />
          <div>
            Час бронювання ваших квитків сплине через
            <span> {minutes}</span>
            <span>:</span>
            <span>{seconds} </span> хв
          </div>
        </div>
      </div>
    </>
  );
};

export default Timer;
