import PageTitle from "../../components/PageTitle/PageTitle";
import "./Offer.css";

export const Offer = () => {
  return (
    <>
      <PageTitle title={"Договір оферти"} />
      <div className="container">
        <div className="content">
          <p className="paragraph">
            Будь ласка, уважно прочитайте Договір публічної оферти, правила і
            умови використання сайту та правила конфіденційності, перед тим, як
            користуватися послугами сайту bus4trip.com.ua. Якщо Ви не згодні з
            Договором публічної оферти, правилами та умовами, будь ласка, не
            використовуйте сайт bus4trip.com.ua. Зауважте, що Договір публічної
            оферти, правила і умови можуть змінюватися без попередження. Зміни в
            Договорі публічної оферти, правилах і умовах набирають чинності з
            моменту їх публікації на сайті bus4trip.com.ua. Здійснення вами
            оплати є свідченням вашого безумовного акцепту договору публічної
            оферти та погодження з правилами й умовами, визначеними власником
            сайту bus4trip.com.ua.
          </p>
          <h2 className="title-text-1">ДОГОВІР ПУБЛІЧНОЇ ОФЕРТИ</h2>
          <p className="paragraph">
            Товариство з обмеженою відповідальністю «Центр пасажирської
            логістики» ЄДРПОУ 45117812, адреса: 03151, Україна, Київ, вулиця
            Волинська 10, керуючись ст. 633. Цивільного кодексу України,
            пропонує необмеженому колу осіб (далі Користувач) укласти Договір
            публічної оферти (далі Договір), з метою пошуку та/або придбання
            квитків за допомогою сайту bus4trip.com.ua, та/або додаткових
            сервісів ТОВ «Центр пасажирської логістики». Користувач може діяти в
            межах Договору від свого імені та на свою користь та/або від імені
            третьої сторони та на її користь, у такому випадку Користувач діє
            виключно в межах повноважень наданих третьою стороною. ТОВ «Центр
            пасажирської логістики» є інтегрованим дистриб'ютором послуг
            перевезення, що поставляються перевізниками, автостанціями,
            консолідаторами ресурсів перевізника та третіми особами, далі
            званими «Постачальник». Квиток (у тому числі і електронний квиток) -
            проїзний документ встановленої форми, який дає право пасажиру на
            одержання транспортних послуг автобусного перевезення, створений в
            автоматизованій системі, надсилається на електронну адресу вказану
            при замовленні або іншим чином видається Користувачу. Номер квитка є
            номером договору публічної оферти і номером договору пасажирського
            перевезення. Користувач і ТОВ «Центр пасажирської логістики»
            обізнані із загальними вимогами укладання Договорів, додержання яких
            є необхідним для чинності правочину, володіючи повним обсягом
            цивільної дієздатності та цивільної правоздатності, мають відповідні
            повноваження на укладання такого правочину, повністю усвідомлюючи
            значення своїх дій та згідно з вільним волевиявленням, яке
            відповідає внутрішній волі учасників цього правочину, маючи на меті
            реальне настання правових наслідків, розуміючи правову природу цього
            правочину, а також свої права та обов’язки за Договором, у
            відповідності до чинного законодавства уклали цей Договір про
            нижчевикладене:
          </p>
          <h2 className="title-text-1">ПРЕДМЕТ ДОГОВОРУ</h2>
          <p className="paragraph">
            1. За Договором, ТОВ «Центр пасажирської логістики» надає послуги з
            пошуку відповідного Постачальника, у відповідності до дати, часу та
            напрямку обраного Користувачем, формування стиковок різних
            Постачальників, укладення договору перевезення (шляхом
            продажу/придбання Квитка), оформлення квитків різних Постачальників
            одним замовленням, попереднього продажу квитків, бронювання місць,
            замовлення квитка по телефону, переоформлення квитка, пошук
            оптимального розкладу за запитом Користувача, сервісне
            обслуговування користувача, у тому числі і цілодобову підтримку
            Користувача Центром сервісного обслуговування, додаткові послуги за
            запитами Користувача, а також попередньої оплати Квитка коштами
            отриманими від Користувача (далі Послуги).
          </p>
          <p className="paragraph">
            2. За Послуги, що надаються, ТОВ «Центр пасажирської логістики»
            отримує винагороду, що складаєтьсяіз суми коштів, що входять до
            вартості Квитка та визначені, як Агентська винагорода (оплачується
            Постачальником)та Сервісний збір (оплачується Користувачем).
          </p>
          <p className="paragraph">
            3. ТОВ ТОВ «Центр пасажирської логістики», діючи як агент, від
            імені, за рахунок в інтересах Постачальника, за допомогою сайту,
            продає автобусне пасажирське перевезення (далі Квиток), спеціальні
            пропозиції, знижки та надає інформацію Постачальника.
          </p>
          <p className="paragraph">
            4. Кожне придбання Квитка окремо взятого Постачальника,
            підпорядковується правилам та умовам саме цього Постачальника і є
            невід’ємною частиною Договору.
          </p>

          <p className="paragraph">
            a. Вчасно повідомити Постачальника про придбаний Квиток, і додаткові
            послуги оплачені Користувачем;
          </p>
          <p className="paragraph">
            b. Надіслати Користувачу Квиток відповідно до форми встановленої і
            прийнятої Постачальником;
          </p>
          <p className="paragraph">
            c. Переказати кошти отримані від Користувача, в якості оплати
            Квитка, на розрахунковий рахунок Постачальника;
          </p>
          <p className="paragraph">
            d.Повідомити Користувача, про зміни в умовах надання послуг (за
            умови повідомлення про такі зміни Постачальником);
          </p>
          <p className="paragraph">
            e. Повідомити Користувача, про зміну або скасування рейсу (у випадку
            надходження такої інформації від Постачальника);
          </p>
          <p className="paragraph">
            f. Прийняти звернення від Користувача, розглянути його самостійно
            або направити для розгляду відповідному Постачальнику;
          </p>
          <p className="paragraph">
            g. Своєчасно повідомити Користувача про розгляд звернення, а у
            випадку надсилання звернення Постачальнику про результати його
            розгляду (у випадку надходження такої відповіді на адресу ТОВ «Центр
            пасажирської логістики»).
          </p>
          <p className="paragraph">
            5. ЗАУВАЖТЕ, ТОВ «Центр пасажирської логістики», надає відповідь на
            звернення, способом аналогічним до його надходження. Надсилання
            відповіді третій стороні (у тому числі і за проханням Користувача)
            здійснюватися не буде
          </p>
        </div>
      </div>
    </>
  );
};

export default Offer;
