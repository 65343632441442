import "./CheckoutInvoice.css";
import { Link } from "react-router-dom";
import { ROUTES } from "../../pages/utils/routes";
import { nanoid } from "nanoid";
import { useState } from "react";

async function sendPromocode(credentials) {
  const token = process.env.REACT_APP_API_TOKEN;
  const domain = process.env.REACT_APP_DOMAIN;
  const url = `${domain}/api/bus4trip/promocode`;

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

const CheckoutInvoice = ({ promocode, setPromocode, handleForm, chooseSeats, ticket, checkouButtonStatus, liqPayRequest, liqPayData, liqPaySignature }) => {
  const [discount, setDiscount] = useState(0);
  const changePromocode = (event) => {
    setPromocode(event.target.value);
  }

  const totalPrice = chooseSeats.length * ticket?.price;

  const handlePromocode = async (e) => {
    const discount = await sendPromocode({
      promocode,
    });
    setDiscount(discount);
  };

  return (
    <>
      <div className="checkout-invoice">
        <div className="checkout-info-promocode">
          <div className="checkout-info-block">
            <div className="checkout-info-total-passenger">{chooseSeats.length} пасажир</div>
            <div className="checkout-info-ticket-price">
              <span>Квиток&nbsp;</span>
              <span className="checkout-info-ticket-price-doted"></span>
              {chooseSeats && chooseSeats.map((item) => <span key={nanoid()} >&nbsp;{ticket?.price}&nbsp;грн.</span>)}
            </div>
          </div>
          <div className="checkout-promocode">
            <input
              className="checkout-promocode-value"
              type="promocode"
              placeholder="Промокод"
              value={promocode}
              onChange={changePromocode}
            />
            <button className="checkout-promocode-button" onClick={handlePromocode}>Застосувати</button>
          </div>
        </div>
        <div className="checkout-total-price">Усього: {(totalPrice * (1 - discount / 100)).toFixed(2)} грн</div>
        <div className="checkout-confirm">
          <button className="checkout-confirm-button" onClick={handleForm} disabled={checkouButtonStatus}>
            Перейти до оплати
          </button>
            {/* <form method="POST" action={liqPayRequest} acceptCharset="utf-8" target="_blank">
                <input type="hidden" name="data" value={liqPayData} />
                <input type="hidden" name="signature" value={liqPaySignature} />
                <button className="checkout-confirm-button">
                  Перейти до оплати
                </button>
            </form> */}
          <div className="checkout-policy">
            <span className="checkout-policy-title">
              Підтверджуючи замовлення, я приймаю:
            </span>
            <div className="checkout-policy-list">
              <span className="checkout-policy-item">
                <span className="checkout-policy-item-dot checkout-policy-interactive">
                  •
                </span>
                <a className="checkout-policy-link" target="_blank">
                  умови повернення
                </a>
              </span>
              <span className="checkout-policy-item">
                <span className="checkout-policy-item-dot checkout-policy-interactive">
                  •
                </span>
                <Link className="checkout-policy-link" to='/offer' target="_blank">
                  публічну оферту
                </Link>
              </span>
              <span className="checkout-policy-item">
                <span className="checkout-policy-item-dot">•</span>даю згоду на
                обробку персональних даних
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutInvoice;
