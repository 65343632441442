import ReturnTicket from "../../../components/ReturnTicket/ReturnTicket.js";
import SendTicket from "../../../components/SendTicket/SendTicket.jsx";
import React, { useState } from "react";

const UserAccountPassengersTableDesktop = ({ item }) => {
  const [returnOpen, setReturnOpen] = useState(false)
  const [sendOpen, setSendOpen] = useState(false)

  const handleConfirm = result => {
    setReturnOpen(false)
    setSendOpen(false)
  }

    return (
        <>
          <div className="user-table-content-row">
            <div className="user-table-content-row-value">
              <div className="passenger-table-cell-username">{item.name} {item.surname}</div>
              <div className="passenger-table-cell-birth-date">{item.birthdate}</div>
              {/* <div className="passenger-table-cell-phone">
                -
              </div> */}
              <div className="passenger-table-cell-email">
              {item.email}
              </div>
              <div className="order-table-cell-status">{item?.status}</div>

              <div className="order-table-cell-image">
                <img src="/image/svg/send-email.svg" alt="not-found" onClick={() => setSendOpen(true)}/>
              </div>
              <div className="order-table-cell-image order-table-cell-image-return">
                <img src="/image/svg/return-ticket.svg" alt="not-found" onClick={() => setReturnOpen(true)}/>
              </div>
            </div>
            <ReturnTicket
              open={returnOpen}
              passenger_id={item.id}
              return_cause={item.return_cause} 
              ticket_id={item?.ticket_id}
              handleConfirm={handleConfirm}
            />
            <SendTicket 
              open={sendOpen}
              passenger_id={item.id}
              passenger_email={item.email}
              ticket_id={item?.ticket_id}
              handleConfirm={handleConfirm}
            />
          </div>
        </>
    );
}

export default UserAccountPassengersTableDesktop;