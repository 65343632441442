import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./layout/Layout/Layout.js";
import { PhoneCode } from "./pages/Auth/PhoneCode/PhoneCode.js";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword.js";
import ResetPassword from "./pages/ResetPassword/ResetPassword.js";
import Login from "./pages/Auth/Login/Login.js";
import MainPage from "./pages/MainPage/MainPage.js";
import Register from "./pages/Auth/Register/Register.js";
import UserAccountOrders from "./pages/UserAccountMenu/UserAccountOrders/UserAccountOrders.js";
import UserAccountPassengers from "./pages/UserAccountMenu/UserAccountPassengers/UserAccountPassengers.js";
import UserAccountSettings from "./pages/UserAccountMenu/UserAccountSettings/UserAccountSettings.js";
import Search from "./pages/Search/Search.js";
import Seats from "./pages/Seats/Seats.jsx";
import UserAccount from "./pages/UserAccount/UserAccount";
import Error404 from "./pages/Error404/Error404.js";
import Booking from "./pages/Booking/Booking.jsx";
import Checkout from "./pages/Checkout/Checkout.jsx";
import Offer from "./pages/Offer/Offer.js";
import { ROUTES } from "./pages/utils/routes";
import Partners from "./pages/Partners/Partners.jsx";
import Logout from "./components/Logout/Logout.js";
import "./style/reset.css";
import "./style/bus4trip.css";
import "./style/header.css";
import "./style/footer.css";
import "./style/goals.css";
import "./style/search.css";
import "./style/ticket-list.css";
import "./style/404.css";
import Confidance from "./pages/Confidance/Confidance.jsx";

function App() {
  const [newSearch, setNewSearch] = useState();
  return (
    <>
      <div className="body-container">
        <BrowserRouter>
          <Layout>
            <Routes>
              <Route
                path={ROUTES.HOME}
                element={<MainPage setNewSearch={setNewSearch} />}
              >
                <Route
                  path={ROUTES.SEARCH}
                  element={<Search newSearch={newSearch} />}
                />
              </Route>
              <Route path={ROUTES.SEATS} element={<Seats />} />
              <Route path={ROUTES.CHECKOUT} element={<Checkout />} />
              <Route path={ROUTES.BOOKING} element={<Booking />} />
              <Route path={ROUTES.REGISTER} element={<Register />} />
              <Route path={ROUTES.LOGIN} element={<Login />} />
              <Route path={ROUTES.VERIFY} element={<PhoneCode />} />
              <Route
                path={ROUTES.FORGOT_PASSWORD}
                element={<ForgotPassword />}
              />
              <Route path={ROUTES.LOGOUT} element={<Logout />} />
              <Route path={ROUTES.USER_ACCOUNT} element={<UserAccount />}>
                <Route path={ROUTES.ORDERS} element={<UserAccountOrders />} />
                <Route
                  path={ROUTES.PASSENGERS}
                  element={<UserAccountPassengers />}
                />
                <Route
                  path={ROUTES.SETTINGS}
                  element={<UserAccountSettings />}
                />
              </Route>
              <Route path={ROUTES.RESET_TOKEN} element={<ResetPassword />} />
              <Route path={ROUTES.OFFER} element={<Offer />} />
              <Route path={ROUTES.CONFIDENCE} element={<Confidance />} />
              <Route path={ROUTES.ERROR_404} element={<Error404 />} />
              <Route path={ROUTES.PARTNERS} element={<Partners />} />
            </Routes>
          </Layout>
        </BrowserRouter>
      </div>
    </>
  );
}
export default App;
