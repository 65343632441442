import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import UserAccountPassengersTable from "./UserAccountPassengersTable";
import UserAccountPassengersEmpty from "./UserAccountPassengersEmpty";
import UserAccountPassengersTableHead from "./UserAccountPassengersTableHead";

export const UserAccountPassengers = () => {
  const { height, width } = useWindowDimensions();
  const [passengers, setPassengers] = useState();
  let tableHead = <UserAccountPassengersTableHead />;

  if (width < 525) {
    tableHead = "";
  }

  useEffect(() => {
    async function FetchData() {
      let token = localStorage.getItem("token");
      const domain = process.env.REACT_APP_DOMAIN;
      const url = `${domain}/api/bus4trip/passengers`;
      return fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => data.json())
        .then((resutl) => {
          setPassengers(resutl.passengers);
        });
    }
    if (passengers === undefined) {
      FetchData();
    }
  }, [passengers]);

  let tableContent = <UserAccountPassengersEmpty />;
  if (passengers?.length) {
    tableContent = <UserAccountPassengersTable passengers={passengers} />;
  }

  return (
    <>
      <div className="user-menu-block">
        {tableHead}
        {tableContent}
      </div>
    </>
  );
};

export default UserAccountPassengers;
