import { Link } from "react-router-dom";
import { ROUTES } from "../../utils/routes";

const UserAccountOrdersEmpty = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0); // Прокручиваем страницу вверх при переходе по ссылке
  };

  return (
    <div className="user-table-empty">
      <img
        className="user-table-empty-image"
        src="/image/img/notfound_img.svg"
        alt="not-found"
      />
      <h2 className="user-table-empty-title">У Вас ще немає замовлень</h2>
      <button className="user-table-empty-button-error user-table-empty-button-active">
        <Link
          to={ROUTES.HOME}
          className="user-table-empty-link"
          onClick={scrollToTop}
        >
          Перейти до пошуку квитків
        </Link>
      </button>
    </div>
  );
};

export default UserAccountOrdersEmpty;
