import React, { useState } from "react";
import './count-passenger.css'

function CountPassenger(props) {
    let [count, setCount] = useState(props.countPassenger);

    function incrementCount() {
      count = parseInt(count, 10) + 1;
      setCount(count);
      props.setCountPassenger(count);
    }
    function decrementCount() {
      if (count > 1) {
        count = parseInt(count, 10) - 1;
        setCount(count);
        props.setCountPassenger(count);
      }
    }
    return (
      <div className="count-block">
        <div className="count-title-mobile">
          <span>Місць</span>
        </div>
        <div className="count-countroll">
          <button className="count-button" onClick={decrementCount}><span className="count-type">-</span></button>
          <div>
            <span className="count-title-desktop">Місць</span>
            <div className="count-value">{count}</div>
          </div>
          <button className="count-button" onClick={incrementCount}><span className="count-type">+</span></button>
        </div>
      </div>
    );
}

export default CountPassenger;
