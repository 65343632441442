import StartPoint from "./StartPoint.js";
import MiddlePoint from "./MiddlePoint.js";
import FinalPoint from "./FinalPoint.js";
import { nanoid } from "nanoid";

const RoadMap = ({ road, departureTime  }) => {
  return (
    <>
      <div className="ticket-info-full-road-map">
        <div className="ticket-info-full-road-map-point-list">
          {road.map((item) => {
            const name = item["arrival_date_time"];
            const time = item["name"];
            const address = item["address"]; 
            if (item["type"] === "start")
              return <StartPoint key={nanoid()} name={departureTime} time={time} address={address} />;
            else if (item["type"] === "final")
              return <FinalPoint key={nanoid()}  name={name} time={time} address={address} />;
            else return <MiddlePoint key={nanoid()}  name={name} time={time} address={address} />;
          })}
        </div>
      </div>
    </>
  );
};

export default RoadMap;
