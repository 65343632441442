import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const Checkbox = ({ children, ...props }: JSX.IntrinsicElements["input"]) => (
  <label style={{ marginRight: "1em" }}>
    <input type="checkbox" {...props} />
    {children}
  </label>
);

const colourOptions = [
  { value: "/user-account/orders", label: "Замовлення" },
  { value: "/user-account/passengers", label: "Мої пасажири" },
  { value: "/user-account/settings", label: "Налаштування" },
];

const UserAccountNavMobile = () => {
  const navigate = useNavigate();
  const [path, setPath] = useState(window.location.pathname);

  let index = colourOptions.findIndex((x) => x.value === path);

  const customStyles = {
    option: (provided) => ({
      color: "#000000",
      padding: 5,
    }),
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
      with: "100%",
    }),
    menu: (base) => ({
      ...base,
      width: "max-content",
      minWidth: "100%",
    }),
  };

  const [isClearable, setIsClearable] = useState(false);
  const [isSearchable, setIsSearchable] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  function handleChange(e) {
    setPath(e.value);
    navigate(e.value);
  }

  return (
    <>
      <div className="mobile-menu">
        <Select
          className="basic-single user-menu-mobile"
          classNamePrefix="select"
          defaultValue={colourOptions[index]}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isRtl={isRtl}
          isSearchable={isSearchable}
          isClearable={isClearable}
          name="color"
          options={colourOptions}
          noOptionsMessage={() => null}
          styles={customStyles}
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export default UserAccountNavMobile;
