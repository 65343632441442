import UserAccountPassengersTableDesktop from "./UserAccountPassengersTableDesktop";
import UserAccountPassengersTableMobile from "./UserAccountPassengersTableMobile";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const UserAccountPassengersTable = ({ passengers }) => {
  const { height, width } = useWindowDimensions();
  return (
    <div className="user-table-content">
      {passengers &&
        passengers.map((item, index) => {
          if (width < 525) {
            return <UserAccountPassengersTableMobile key={index} item={item} />;
          } else {
            return (
              <UserAccountPassengersTableDesktop key={index} item={item} />
            );
          }
        })}
    </div>
  );
};

export default UserAccountPassengersTable;
