import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../pages/utils/routes";
import { useLocation } from "react-router-dom";
import CityFrom from "../CityFrom/CityFrom.jsx";
import CityTo from "../CityTo/CityTo.jsx";
import DateFrom from "../DateFrom/DateFrom.jsx";
import CountPassenger from "../CountPassenger/CountPassenger.jsx";
import useQueryParams from "../../hooks/useQueryParams.js";
import "./search.css";
import "react-datepicker/dist/react-datepicker.css";

export const SearchForm = (setNewSearch) => {
  const navigate = useNavigate();
  const search = useQueryParams();
  const location = useLocation();
  const page = location.pathname;

  const cityFrom = search["city-from"] !== undefined ? search["city-from"] : null;
  const cityTo = search["city-to"] !== undefined ? search["city-to"] : null;
  const date =
    search["date"] === undefined
      ? new Date().toLocaleDateString("uk-Uk")
      : search["date"];

  const passengers =
    search["passengers"] !== undefined ? search["passengers"] : 1;

  const [cityFromId, setCityFromId] = useState(cityFrom);
  const [cityToId, setCityToId] = useState(cityTo);
  const [startDate, setStartDate] = useState(date);
  const [countPassenger, setCountPassenger] = useState(passengers);

  const formQuery =
    ROUTES.SEARCH +
    `?lang=uk&city-from=${cityFromId}&city-to=${cityToId}&date=${startDate}&passengers=${countPassenger}`;

  const handleSearch = () => {
    setNewSearch(formQuery)
  }

  const initSearch = () => {
    navigate(formQuery);
  }

  function handleChange(e) {
    setCityFromId(e.currentTarget.value);
  }

  let title = {};
  let shortBlock = "";
  let searchForm = "";

  if (page === "/search") {
    title = { display: "none" };
    shortBlock = "search-block-on-ticket-page";
    searchForm = "search-form-on-ticket-page";
  }
  return (
    <>
      <div className={`search-block ${shortBlock}`}>
        <div className="search-title" style={title}>
          Пошук квитків на автобус
        </div>
        <div className={`search-form ${searchForm}`}>
          <div className="search-form-fields ">
            <div className="form-field form-field-from">
              <CityFrom cityFromId={cityFromId} setCityFromId={setCityFromId} />
            </div>
            <div className="form-field form-field-to">
              <CityTo
                cityToId={cityToId}
                cityFromId={cityFromId}
                setCityToId={setCityToId}
              />
            </div>
            <div className="form-field form-field-date">
              <DateFrom startDate={startDate} setStartDate={setStartDate} />
            </div>
            <div className="form-field form-field-place">
              <div className="form-field-place-counter">
                <CountPassenger
                  countPassenger={countPassenger}
                  setCountPassenger={setCountPassenger}
                />
              </div>
            </div>
          </div>
          {/* <Link className="search-form-button-link" to={formQuery} onClick={handleSearch}>
            <div className="search-form-button">
              <img
                className="search-form-button-icon"
                src="/image/svg/finder-icon.svg"
              />
              <span className="search-form-button-title">Знайти</span>
            </div>
          </Link> */}
            <button className="search-form-button" onClick={initSearch}
              disabled={cityFromId && cityToId ? false : true}
            >
              <img
                className="search-form-button-icon"
                src="/image/svg/finder-icon.svg"
              />
              <span className="search-form-button-title">Знайти</span>
            </button>
        </div>
      </div>
    </>
  );
};

export default SearchForm;
