export const Auth = ({ children }) => {
  return (
    <>
      <div className="auth">
        <div className="auth-info">
          <img src="/image/img/Group-87.svg" alt="register logo" />
        </div>
        <div className="auth-menu">{children}</div>
      </div>
    </>
  );
};

export default Auth;
