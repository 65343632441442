import PageTitle from "../../components/PageTitle/PageTitle";
import "./Confidance.css";

export const Confidance = () => {
  return (
    <>
      <PageTitle title={"Політика конфіденційності"} />
      <div className="container">
        <div className="content">
          <h2 className="title-text-1">ПРЕДМЕТ ДОГОВОРУ</h2>
          <p className="paragraph">
            1. За Договором, ТОВ «Центр пасажирської логістики» надає послуги з
            пошуку відповідного Постачальника, у відповідності до дати, часу та
            напрямку обраного Користувачем, формування стиковок різних
            Постачальників, укладення договору перевезення (шляхом
            продажу/придбання Квитка), оформлення квитків різних Постачальників
            одним замовленням, попереднього продажу квитків, бронювання місць,
            замовлення квитка по телефону, переоформлення квитка, пошук
            оптимального розкладу за запитом Користувача, сервісне
            обслуговування користувача, у тому числі і цілодобову підтримку
            Користувача Центром сервісного обслуговування, додаткові послуги за
            запитами Користувача, а також попередньої оплати Квитка коштами
            отриманими від Користувача (далі Послуги).
          </p>
          <p className="paragraph">
            2. За Послуги, що надаються, ТОВ «Центр пасажирської логістики»
            отримує винагороду, що складаєтьсяіз суми коштів, що входять до
            вартості Квитка та визначені, як Агентська винагорода (оплачується
            Постачальником)та Сервісний збір (оплачується Користувачем).
          </p>
          <p className="paragraph">
            3. ТОВ ТОВ «Центр пасажирської логістики», діючи як агент, від
            імені, за рахунок в інтересах Постачальника, за допомогою сайту,
            продає автобусне пасажирське перевезення (далі Квиток), спеціальні
            пропозиції, знижки та надає інформацію Постачальника.
          </p>
          <p className="paragraph">
            4. Кожне придбання Квитка окремо взятого Постачальника,
            підпорядковується правилам та умовам саме цього Постачальника і є
            невід’ємною частиною Договору.
          </p>

          <p className="paragraph">
            a. Вчасно повідомити Постачальника про придбаний Квиток, і додаткові
            послуги оплачені Користувачем;
          </p>
          <p className="paragraph">
            b. Надіслати Користувачу Квиток відповідно до форми встановленої і
            прийнятої Постачальником;
          </p>
          <p className="paragraph">
            c. Переказати кошти отримані від Користувача, в якості оплати
            Квитка, на розрахунковий рахунок Постачальника;
          </p>
          <p className="paragraph">
            d.Повідомити Користувача, про зміни в умовах надання послуг (за
            умови повідомлення про такі зміни Постачальником);
          </p>
          <p className="paragraph">
            e. Повідомити Користувача, про зміну або скасування рейсу (у випадку
            надходження такої інформації від Постачальника);
          </p>
          <p className="paragraph">
            f. Прийняти звернення від Користувача, розглянути його самостійно
            або направити для розгляду відповідному Постачальнику;
          </p>
          <p className="paragraph">
            g. Своєчасно повідомити Користувача про розгляд звернення, а у
            випадку надсилання звернення Постачальнику про результати його
            розгляду (у випадку надходження такої відповіді на адресу ТОВ «Центр
            пасажирської логістики»).
          </p>
          <p className="paragraph">
            5. ЗАУВАЖТЕ, ТОВ «Центр пасажирської логістики», надає відповідь на
            звернення, способом аналогічним до його надходження. Надсилання
            відповіді третій стороні (у тому числі і за проханням Користувача)
            здійснюватися не буде
          </p>
        </div>
      </div>
    </>
  );
};

export default Confidance;
