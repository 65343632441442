import "./ReturnTicket.css";

async function returnTicket(credentials) {
  const token = localStorage.getItem("token");
  const domain = process.env.REACT_APP_DOMAIN;
  const url = `${domain}/api/bus4trip/booking/return/ticket`;
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

const ReturnTicket = ({ open, passenger_id, ticket_id, return_cause, handleConfirm }) => {
  const handleReturnTicket = async (e) => {
    e.preventDefault();
    const response = await returnTicket({
      passenger_id: passenger_id,
      ticket_id: ticket_id,
    });
    handleConfirm(true);
  };

 return (
    <>
      <div className={open ? "confirm show" : "confirm"}>
        <div className="return-ticket-content">
          {return_cause && return_cause.map(cause => (
            <span key={cause.id} className="return-ticket-content-info">
              {cause.cause}
            </span>
          ))}
          
          <button
            className="return-ticket-content-button"
            onClick={handleReturnTicket}
          >
            Повернути квиток
          </button>
        </div>
      </div>
      <div className="overlay" onClick={() => handleConfirm(false)} />
    </>
  );
};

export default ReturnTicket;
