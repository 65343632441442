import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import BusSchemeFloor from "../../components/BusScheme/BusSchemeFloor.jsx";
import Step from "../../components/Step/Step.jsx";
import useQueryParams from "../../hooks/useQueryParams.js";
import { Watch } from "react-loader-spinner";
import "./Seats.css";

const Seats = () => {
  const navigate = useNavigate();
  const query = useQueryParams("");
  const [params, setParams] = useState("");
  const [stepType, setStepType] = useState("start");
  const [busScheme, setBusScheme] = useState(null);
  const [chooseSeats, setChooseSeats] = useState([]);
  const [currentSearchQuery, setCurrentSearchQuery] = useState(
    sessionStorage.getItem("currentSearchQuery")
  );
  sessionStorage.removeItem(`chooseSeats-${query?.trip_id}`);
  const [checkout, setCheckout] = useState(
    sessionStorage.getItem("currentCheckoutLink")
  );
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    setParams(
      `trip_id=${query.trip_id}&date=${query.date}&passengers=${query.passengers}`
    );
  }, [chooseSeats]);

  const token = process.env.REACT_APP_API_TOKEN;
  const domain = process.env.REACT_APP_DOMAIN;
  const url = `${domain}/api/bus4trip/check-seats?${params}`;
  
  useEffect(() => {
    async function FetchData() {
      setLoading(true); // Start loading
      fetch(url, { mode: "cors" })
        .then((res) => res.json())
        .then((result) => {
          if (result && result["bus_scheme"]) {
            setBusScheme(result["bus_scheme"]);
          }
          setLoading(false); // End loading
        });
    }
    if (params !== "") {
      FetchData();
    }
  }, [params]);

  const handleChooseSeat = (id, seat, tariffs) => {
    const currentSeat = {
      id: id,
      seat: seat,
      tariffs: tariffs,
    };
    const seatIndex = chooseSeats.findIndex((seat) => seat.id === id);
    if (seatIndex > -1) {
      const newChooseSeats = [...chooseSeats];
      newChooseSeats.splice(seatIndex, 1);
      setChooseSeats(newChooseSeats);
    } else if (chooseSeats.length < query["passengers"]) {
      setChooseSeats([...chooseSeats, currentSeat]);
    }
    console.log("Updated chosen seats:", chooseSeats); // Debug
  };

  const handleChooseButton = () => {
    if (busScheme.is_free_seating) {
      const availableSeat = Object.values(busScheme.seats).flat().find(seat => seat.status === "free");
      if (availableSeat) {
        setChooseSeats([{ id: availableSeat.id, seat: availableSeat.number, tariffs: availableSeat.tariffs }]);
        sessionStorage.setItem(
          `chooseSeats-${query?.trip_id}`,
          JSON.stringify([{ id: availableSeat.id, seat: availableSeat.number, tariffs: availableSeat.tariffs }])
        );
      }
    } else {
      sessionStorage.setItem(
        `chooseSeats-${query?.trip_id}`,
        JSON.stringify(chooseSeats)
      );
    }
    window.scrollTo(0, 0);
    navigate(checkout);
  };

  useEffect(() => {
    console.log("Bus Scheme:", busScheme);
  }, [busScheme]);

  if (loading) {
    return (
      <div className="loaderRoot">
        <div className="loaderStyle">
          <Watch
            height="80"
            width="80"
            radius="48"
            color="#4fa94d"
            ariaLabel="watch-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      </div>
    );
  }

  if (busScheme === null) {
    return null; // Or a different loader component
  }

  const isFreeSeating = busScheme.is_free_seating;
  const buttonText = isFreeSeating ? "Продовжити" : "Обрати";
  const isButtonDisabled = !isFreeSeating && chooseSeats.length === 0;

  console.log("Button Disabled:", isButtonDisabled); // Debug
  console.log("Number of Passengers:", query["passengers"]);
  console.log("Number of Chosen Seats:", chooseSeats.length);

  return (
    <>
      <div className="page-head">
        <Step type={stepType} />
      </div>
      <div className="choose-places">
        <span className="choose-places-title">
          {isFreeSeating ? "Вільна розсадка" : "Виберіть місце на схемі автобуса"}
        </span>
        {!isFreeSeating && (
          <>
            <div className="choose-place-legend">
              <div className="legend">
                <div className="legend-icon legend-icon-free"></div>
                <span className="legend-title">Вільне</span>
              </div>
              <div className="legend">
                <div className="legend-icon legend-icon-busy"></div>
                <span className="legend-title">Зайняте</span>
              </div>
            </div>
            <div className="bus-scheme">
              {busScheme &&
                busScheme["seats"] &&
                Object.values(busScheme["seats"]).map((floor, index) => {
                  return (
                    <BusSchemeFloor
                      floor={floor}
                      key={index}
                      index={index}
                      chooseSeats={chooseSeats}
                      handleChooseSeat={handleChooseSeat}
                    />
                  );
                })}
            </div>
          </>
        )}
        <div className="choose-places-controller">
          <button
            className="choose-places-controller-link choose-places-button choose-confirm"
            onClick={handleChooseButton}
            disabled={isButtonDisabled}
          >
            {buttonText}
          </button>
          <Link
            to={currentSearchQuery}
            className="choose-places-controller-link"
          >
            <button className="choose-places-button choose-other">
              Шукати далі
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Seats;
