import { Link } from "react-router-dom";
import "./OrderSuccess.css";
import { ROUTES } from "../../pages/utils/routes";

const OrderSuccess = () => {
  return (
    <>
      <div className="booking-block">
        <div className="booking-info">
          <div className="booking-info-title">
            <span className="booking-info-title-value">
             Ваші квитки успішно заброньовані!
            </span>
          </div>
          <div className="booking-logo-mobile">
            <img
              className="booking-logo-image"
              src="./image/img/Group-87.svg"
              alt=""
            />
          </div>
          <div className="booking-info-text-head">
            <span className="booking-info-text-head-value">
              Ми відправили їх на вашу пошту.
              <br />
              <br />
              Також ми створили вам особистий кабінет, щоб ви завжди могли:
              переглянути і скачати наявні квитки, передати їх іншій людині,
              перевірити кількість накопичених бонусів.
              <br />
              <br />
              Данні для входу буде дуже легко запам’ятати тому що це ваш імейл
              та номер телефону:
              <br />
              <br />
            </span>
          </div>
          {/* <div className="booking-info-user">
            <div>
              <span className="booking-info-user-title">Логін:</span>
              <span className="booking-info-user-value">
                <b>max2349@gmail.com</b>
              </span>
            </div>
            <div>
              <span className="booking-info-user-title">Пароль:</span>
              <span className="booking-info-user-value">
                <b>0935083241</b>
              </span>
            </div>
          </div> */}
          <div className="booking-info-controller">
            <Link
              className="booking-info-controller-login"
              to={ROUTES.USER_ACCOUNT}
            >
              Перейти в особистий кабінет
            </Link>
            <Link className="booking-info-controller-main-page" to={ROUTES.HOME}>
              Повернутись на головну
            </Link>
          </div>
        </div>
        <div className="booking-logo-desktop">
          <img
            className="booking-logo-image"
            src="./image/img/Group-87.svg"
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default OrderSuccess;
