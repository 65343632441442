import UserAccountOrdersTableItemDesktop from "./UserAccountOrdersTableItemDesktop";
import UserAccountOrdersTableItemMobile from "./UserAccountOrdersTableItemMobile";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const UserAccountOrdersTable = ({ booking }) => {
  const { height, width } = useWindowDimensions();

  return (
    <div className="user-table-content">
      {booking &&
        booking.map((item, index) => {
          if (width < 525) {
            return <UserAccountOrdersTableItemMobile key={index} item={item} />;
          } else {
            return (
              <UserAccountOrdersTableItemDesktop key={index} item={item} />
            );
          }
        })}
    </div>
  );
};

export default UserAccountOrdersTable;
