import React, { useState } from "react";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { ROUTES } from "../../utils/routes";
import Auth from "../../../layout/Auth/Auth.js";

async function forgotPassword(credentials) {
  const token = process.env.REACT_APP_API_TOKEN;
  const domain = process.env.REACT_APP_DOMAIN;
  const url = `${domain}/api/auth/forgot-password`;
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export const ForgotPassword = () => {
  const [email, setEmail] = useState("+380");
  const [phone, setPhone] = useState("+380");

  const handleSubmit = async (e) => {
    e.preventDefault();
    await forgotPassword({
      email,
      phone, 
    });

    swal(
      "Ми надіслали вам e-mail для відновлення пароля",
      "Перейдіть за посиланням, щоб задати пароль",
      "success",
      {
        buttons: false,
        timer: 20000,
      }
    );
  };

  const changeEmail = (event) => {
    const regex = /[^\d+()\-\s]/g;
    const inputValue = event.target.value;
    const cleanedValue = inputValue.replace(regex, "");

    setEmail(cleanedValue);
  };

  const changePhone = (event) => {
    const regex = /[^\d+()\-\s]/g;
    const inputValue = event.target.value;
    const cleanedValue = inputValue.replace(regex, "");

    setPhone(cleanedValue);
  };

  return (
    <>
      <PageTitle title={"Відновити пароль"} />
      <Auth>
        <form className="auth-form" onSubmit={handleSubmit}>
          <div className="user-input-wrp">
            <br />
            <input
              required
              name="phone"
              className="inputText"
              onChange={changePhone}
              value={phone}
              autoComplete="off"
            />
            <span className="floating-label">Номер телефону</span>
          </div>
          <button className="auth-submit" type="submit">
            Надіслати
          </button>
          <div className="other-options">
            <div className="register-account">
              <span className="register-account-title">Можливо щось інше?</span>
            </div>
            <Link className="register-account-link" to={ROUTES.LOGIN}>
              Перейти до входу
            </Link>
            <Link className="register-account-link" to={ROUTES.REGISTER}>
              Зареєструвати новий аккаунт
            </Link>
          </div>
        </form>
      </Auth>
    </>
  );
};
export default ForgotPassword;
