import React, { useState } from "react";
import "./SendTicket.css";

async function sendTicket(credentials) {
  const token = localStorage.getItem("token");
  const domain = process.env.REACT_APP_DOMAIN;
  const url = `${domain}/api/bus4trip/booking/send/ticket`;
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

const SendTicket = ({
  open,
  passenger_id,
  passenger_email,
  ticket_id,
  handleConfirm,
}) => {
  const [email, setEmail] = useState(passenger_email);

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSend = async (e) => {
    e.preventDefault();
    const response = await sendTicket({
      passenger_id: passenger_id,
      ticket_id: ticket_id,
      email: email,
    });
    handleConfirm(false);
    setEmail(null);
  };

  return (
    <>
      <div className={open ? "confirm show" : "confirm"}>
        <div className="return-ticket-content">
          <span className="return-ticket-content-title">
            Надіслати на пошту
          </span>
          {/* <input type="email" onChange={handleEmail} value={email} /> */}
          <input className="return-ticket-form-input" type="email" onChange={handleEmail} value={email} />
          <button className="return-ticket-content-button" onClick={handleSend}>
            Повернути квиток
          </button>
        </div>
      </div>
      <div className="overlay" onClick={() => handleConfirm(false)} />
    </>
  );
};

export default SendTicket;
