import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import PageTitle from "../../../components/PageTitle/PageTitle";
import PhoneCodeResend from "../../../components/PhoneCodeResend/PhoneCodeResend.js";
import { ROUTES } from "../../utils/routes";
import Auth from "../../../layout/Auth/Auth.js";

async function registerUser(credentials) {
  const token = process.env.REACT_APP_API_TOKEN;
  const domain = process.env.REACT_APP_DOMAIN;
  const url = `${domain}/api/phone/verify`;
  // Зробити запит на реєстрацію користувача
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export const PhoneCode = () => {
  const navigate = useNavigate();

  const [code, setCode] = useState();
  const [phone, setPhone] = useState(localStorage.getItem("phone"));

  if (!phone) navigate("/register");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await registerUser({
      phone,
      code,
    });

    if ("status" in response) {
      if (response.status === "success") {
        // swal(
        //   "Код прийнято",
        //   "Усі подорожі потрібно підтверджувати через смс",
        //   "success",
        //   {
        //     buttons: false,
        //     timer: 20000,
        //   }
        // ).then((value) => {
        navigate("/login");
        // });
      } else {
        swal("Не вірний код", "Спробуйте ввести ще раз", "error", {
          buttons: false,
          timer: 20000,
        });
      }
    } else {
    }
  };

  return (
    <>
      <PageTitle title={"СМС підтвердження"} />
      <Auth>
        <form className="auth-form" onSubmit={handleSubmit}>
          <div className="fake-input">
            <span className="fake-input-title">Номер телефону</span>
            <span className="fake-input-value">{phone}</span>
          </div>
          <div className="user-input-wrp">
            <br />
            <input
              required
              id="email"
              name="email"
              className="inputText"
              type="text"
              onChange={(e) => setCode(e.target.value)}
              autoComplete="off"
            />
            <span className="floating-label">Код підтвердження</span>
          </div>
          <PhoneCodeResend phone={phone} />
          <button className="auth-submit" type="submit">
            Продовжити
          </button>
          <div className="other-options">
            <div className="register-account">
              <span className="register-account-title">Маєш акаунту?</span>
            </div>
            <Link className="register-account-link" to={ROUTES.LOGIN}>
              Перейти до входу
            </Link>
          </div>
        </form>
      </Auth>
    </>
  );
};

export default PhoneCode;
