import { useEffect, useState } from "react";
import "./ReserveTicket.css"

const ReserveTicket = ({ ticket }) => {
  const [startPoint, setStartPoint] = useState();
  const [finalPoint, setFinalPoint] = useState();
  const [currentSearchQuery, setCurrentSearchQuery] = useState(
    sessionStorage.getItem("currentSearchQuery")
  );

  useEffect(() => {
    if (ticket !== undefined) {
      setStartPoint(ticket["route"].find((point) => point.type === "start"));
      setFinalPoint(ticket["route"].find((point) => point.type === "final"));
    }
  }, [ticket]);

  return (
    <>
      <div className="checkout-ticket-block">
        <span className="checkout-ticket-block-title">Ваші квитки</span>
        <div className="checkout-ticket">
          <div className="ticket-item checkout-ticket-item">
            <div className="ticket-head-info">
              <div className="ticket-time">
                <div className="ticket-time-departure">
                  <span className="ticket-time-departure-time">{ticket?.departure_time}</span>
                  <span className="ticket-time-departure-date">{ticket?.departure_date}</span>
                </div>
                <div className="ticket-time-in-the-way">
                  <div className="ticket-time-in-the-way-block">
                    <img
                      className="ticket-time-in-the-way-image"
                      src="./image/svg/time.svg"
                      alt=""
                    />
                    <span className="ticket-time-in-the-way-info">{ticket?.travel_time}</span>
                  </div>
                  <div className="ticket-time-in-the-way-type-mobile">
                    <span>прямий рейс</span>
                  </div>
                </div>
                <div className="ticket-time-arrival">
                  <span className="ticket-time-arrival-time">{ticket?.arrival_time}</span>
                  <span className="ticket-time-arrival-date">{ticket?.arrival_date}</span>
                </div>
                <div className="ticket-proposal ticket-proposal-desktop">
                  <div className="ticket-proposal-place"></div>
                  <div className="ticket-proposal-price">
                    <span className="ticket-proposal-price-value">{ticket?.price} ₴</span>
                  </div>
                </div>
              </div>
              <div className="ticket-short-map">
                <div className="ticket-time-departure-point">
                  {startPoint?.address}
                </div>
                <div className="ticket-time-without-bus-change">
                  <span>прямий рейс</span>
                </div>
                {/* <div className="ticket-time-transplantation-point">
                  <span>Пересадка Львів АВ</span>
                  <span className="ticket-time-transplantation-point-duration">
                    очікування 45 хв
                  </span>
                </div> */}
                <div className="ticket-time-arrival-point">
                  {finalPoint?.address}
                </div>
                <div className="ticket-time-arrival-point-desktop">
                  <a href={currentSearchQuery} className="checkout-ticket-cancel">
                    Обрати інший квиток
                  </a>
                </div>
              </div>
              <div className="ticket-proposal ticket-proposal-mobile">
                <div className="ticket-proposal-price">
                  <span className="ticket-proposal-mobile-title">Ціна:</span>
                  <span className="ticket-proposal-price-value">{ticket?.price} ₴</span>
                </div>
                <a href={currentSearchQuery} className="checkout-ticket-cancel">
                  Обрати інший квиток
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReserveTicket;
