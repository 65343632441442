import { useEffect, useState } from "react";
import CheckoutPassengerBirthdate from "./CheckoutPassengerBirthdate.jsx";
import CheckoutPassengerBaggage from "./CheckoutPassengerBaggage.jsx";
import CheckoutPassengerDiscount from "./CheckoutPassengerDiscount.jsx";
import "./CheckoutPassenger.css";

const CheckoutPassenger = ({ seat, index, passengers, setPassengers }) => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [birthdate, setBirthdate] = useState(new Date());
  const [baggage, setBaggage] = useState(null);
  const [discount, setDiscount] = useState(null);

  useEffect(() => {
    passengers[index] = {
      name: name,
      surname: surname,
      birthdate: birthdate,
      baggage: baggage,
      discount: discount,
      seat_id: seat.id,
    }
    setPassengers(passengers);
  }, [name, surname, birthdate, baggage, discount]);

  const changeName = (event) => {
    const regex = /[^a-zA-Zа-яА-ЯіІїЇєЄёЁ\s]/g; // Регулярное выражение
    const inputValue = event.target.value;
    const cleanedValue = inputValue.replace(regex, '');
    setName(cleanedValue);
  };

  const changeSurname = (event) => {
    const regex = /[^a-zA-Zа-яА-ЯіІїЇєЄёЁ\s]/g; // Регулярное выражение
    const inputValue = event.target.value;
    const cleanedValue = inputValue.replace(regex, '');
    setSurname(cleanedValue);
  };

  return (
    <>
      <div className="checkout-passenger-item">
        <div className="checkout-passenger-item-place">
          <div className="checkout-passenger-item-place-title">
            Місце {seat.seat}
          </div>
        </div>
        <div className="checkout-passenger-item-person">
          <div className="checkout-input-wraper">
            <br />
            <input
              required
              name="name"
              className="checkout-input-field checkout-passenger-item-input-name"
              autoComplete="off"
              value={name}
              onChange={changeName}
            />
            <span className="checkout-input-label">Імʼя</span>
          </div>
          <div className="checkout-input-wraper">
            <br />
            <input
              required
              name="surname"
              className="checkout-input-field checkout-passenger-item-input-surname"
              autoComplete="off"
              value={surname}
              onChange={changeSurname}
            />
            <span className="checkout-input-label">Прізвище</span>
          </div>
          {/* <CheckoutPassengerBirthdate setBirthdate={setBirthdate} /> */}
        </div>
        {/* <div className="checkout-passenger-item-configuration">
          <CheckoutPassengerBaggage setBaggage={setBaggage} />
          <CheckoutPassengerDiscount setDiscount={setDiscount} tariffs={seat?.tariffs} />
        </div> */}
      </div>
    </>
  );
};

export default CheckoutPassenger;
