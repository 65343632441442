const MiddlePoint = ({ name, time }) => {
  return (
    <>
      <div className="ticket-info-full-road-map-point-item">
        <div className="ticket-info-full-road-map-chapter"></div>
        <div>
          <span className="ticket-info-full-road-map-point-image">
            <img
              className="ticket-info-full-road-map-point-image-dot"
              src="./image/svg/point-dot.svg"
              alt=""
            />
            <img
              className="ticket-info-full-road-map-point-image-line ticket-info-full-road-map-point-image-line-mobile"
              src="./image/svg/point-line.svg"
              alt=""
            />
            <img
              className="ticket-info-full-road-map-point-image-line ticket-info-full-road-map-point-image-line-desktop"
              src="./image/png/point-line-long.png"
              alt=""
            />
          </span>
        </div>
        <div className="ticket-info-full-road-map-point-detail">
          <span className="point-time">{name}</span>
          <span className="point-title">{time}</span>
        </div>
      </div>
    </>
  );
};

export default MiddlePoint;
