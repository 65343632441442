import React, { useEffect, useState } from "react";
import { getPadTime } from "../../helpers/getPadTime";

async function sendNewVerifyCode(credentials) {
  const token = process.env.REACT_APP_API_TOKEN;
  const domain = process.env.REACT_APP_DOMAIN;
  const url = `${domain}/api/resend/code`;
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export const PhoneCodeResend = ({ phone }) => {
  const waitTime = 15;
  const [buttonStatus, setButtonStatus] = useState(true);
  const [timeLeft, setTimeLeft] = useState(waitTime);
  const [isCounting, setIsCounting] = useState(true);
  const minutes = getPadTime(Math.floor(timeLeft / 60));
  const seconds = getPadTime(timeLeft - minutes * 60);

  useEffect(() => {
    const interval = setInterval(() => {
      isCounting &&
        setTimeLeft((timeLeft) => (timeLeft >= 1 ? timeLeft - 1 : 0));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [isCounting]);

  const resendCode = async (e) => {
    e.preventDefault();
    await sendNewVerifyCode({
      phone,
    });
    setTimeLeft(waitTime);
    setIsCounting(true);
    setButtonStatus(true);
  };

  if (timeLeft === 0 && buttonStatus === true) {
    setButtonStatus("");
  }

  return (
    <>
      <p className="phone-code-notification">
        На ваш номер було відправлене СМС з кодом. Відправити новий код доступу
        Ви зможете через:
        <div className="phone-code-timer">
          <span>{minutes}</span>
          <span>:</span>
          <span>{seconds}</span>
        </div>
      </p>
      <button
        className="phone-code-resend-button"
        onClick={resendCode}
        disabled={buttonStatus}
      >
        Відправити код повторно
      </button>
    </>
  );
};

export default PhoneCodeResend;
