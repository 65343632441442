import BusSchemeRow from "./BusSchemeRow.jsx";
import { nanoid } from "nanoid";

const BusSchemeFloor = ({ floor, index, chooseSeats, handleChooseSeat }) => {
  return (
    <>
      <div className="bus-floor">
        {index === 0 && (
          <div className="seats-driver">
            <img src="./image/svg/steering.svg" alt="steering" />
          </div>
        )}
        <div className="seats-list">
          {floor &&
            Object.values(floor).map((row, index) => {
              return (
                <BusSchemeRow
                  row={row}
                  key={nanoid()}
                  chooseSeats={chooseSeats}
                  handleChooseSeat={handleChooseSeat}
                />
              );
            })}
        </div>
      </div>
    </>
  );
};

export default BusSchemeFloor;
