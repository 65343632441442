import React, { useState } from "react";
import Logout from "../../../components/Logout/Logout.js";

export const UserAccountSettings = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("+380");

  const handleForm = async () => {
    const credentials = {
      name: name,
      surname: surname,
      phone: phone,
      email: email,
    };

    let token = process.env.REACT_APP_API_TOKEN;
    const domain = process.env.REACT_APP_DOMAIN;
    const url = `${domain}/api/bus4trip/user-info`;
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(credentials),
    });
  };

  const changePhone = (event) => {
    const regex = /[^\d+()\-\s]/g;
    const inputValue = event.target.value;
    const cleanedValue = inputValue.replace(regex, "");

    setPhone(cleanedValue);
  };

  const changeName = (event) => {
    const regex = /[^a-zA-Zа-яА-ЯіІїЇєЄёЁ\s]/g; // Регулярное выражение
    const inputValue = event.target.value;
    const cleanedValue = inputValue.replace(regex, "");
    setName(cleanedValue);
  };

  const changeSurname = (event) => {
    const regex = /[^a-zA-Zа-яА-ЯіІїЇєЄёЁ\s]/g; // Регулярное выражение
    const inputValue = event.target.value;
    const cleanedValue = inputValue.replace(regex, "");
    setSurname(cleanedValue);
  };

  return (
    <>
      <div className="user-menu-block">
        {/* <form className="carriers-form-main" action="#">
          <div className="carriers-form-fields">
            <div className="user-input-wrp">
              <br />
              <input
                required
                id="name"
                name="name"
                className="inputText"
                value={name}
                onChange={changeName}
                autoComplete="off"
              />
              <span className="floating-label">Імʼя</span>
            </div>
            <div className="user-input-wrp">
              <br />
              <input
                required
                id="name"
                name="name"
                className="inputText"
                value={surname}
                onChange={changeSurname}
                autoComplete="off"
              />
              <span className="floating-label">Прізвище</span>
            </div>
            <div className="user-input-wrp">
              <br />
              <input
                required
                id="email"
                name="email"
                className="inputText"
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="off"
              />
              <span className="floating-label">Email</span>
            </div>
            <div className="user-input-wrp">
              <br />
              <input
                required
                id="phone"
                name="phone"
                onChange={changePhone}
                value={phone}
                className="inputText"
                autoComplete="off"
              />
              <span className="floating-label">Номер телефону</span>
            </div>
          </div>
          <button
            className="carriers-form-button"
            type="submit"
            onClick={handleForm}
            disabled={name && phone && email ? false : true}
          >
            Онововити дані
          </button>
        </form> */}
        <div className="user-settings">
          <Logout />
        </div>
      </div>
    </>
  );
};

export default UserAccountSettings;
