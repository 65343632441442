import UserAccountNav from "../../components/UserAccountNav/UserAccountNav";
import UserAccountNavMobile from "../../components/UserAccountNav/UserAccountNavMobile";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Outlet } from "react-router-dom";
import "./UserAccount.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export const UserAccount = ({ children }) => {
  const { height, width } = useWindowDimensions();
  let menu = <UserAccountNav />;

  if (!localStorage.getItem("token") || !localStorage.getItem("user_id"))
    window.location.href = "/login";

  if (width < 525) {
    menu = <UserAccountNavMobile />;
  }

  return (
    <>
      <PageTitle title="Особистий кабінет" />
      <section className="user-account">
        {menu}
        <Outlet />
      </section>
    </>
  );
};

export default UserAccount;
