import React, { useState } from "react";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Auth from "../../../layout/Auth/Auth.js";
import { ROUTES } from "../../../pages/utils/routes";

async function loginUser(credentials) {
  const token = process.env.REACT_APP_API_TOKEN;
  const domain = process.env.REACT_APP_DOMAIN;
  const url = `${domain}/api/auth/login`;
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export const Login = () => {
  const [phone, setPhone] = useState("+380");
  const [password, setPassword] = useState();
  const [remember, setRemember] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await loginUser({
      phone,
      password,
      remember,
    });

    if (response.status === false) {
      swal(
        "Failed",
        response.message,
        "Не правильний номер телефону або пароль"
      );
    }

    if ("token" in response.content) {
      // swal("Success", response.message, "success", {
      //   buttons: false,
      //   timer: 2000,
      // }).then((value) => {
      localStorage.setItem("token", response.content["token"]);
      localStorage.setItem(
        "user_id",
        JSON.stringify(response.content["user_id"])
      );
      window.location.href = "/user-account/orders";
      // });
    }
  };

  const changePhone = (event) => {
    const regex = /[^\d+()\-\s]/g;
    const inputValue = event.target.value;
    const cleanedValue = inputValue.replace(regex, "");

    setPhone(cleanedValue);
  };

  const changePassword = (event) => {
    const regex = /[^\d+()\-\s]/g;
    const inputValue = event.target.value;
    const cleanedValue = inputValue.replace(regex, "");

    setPassword(cleanedValue);
  };

  const handleClick = () => setRemember(!remember);

  return (
    <>
      <PageTitle title={"Вхід"} />
      <Auth>
        <form className="auth-form" onSubmit={handleSubmit}>
          <div className="user-input-wrp">
            <br />
            <input
              required
              id="phone"
              name="phone"
              className="inputText"
              value={phone}
              onChange={changePhone}
              autoComplete="off"
            />
            <span className="floating-label">Номер телефону</span>
          </div>
          <div className="user-input-wrp">
            <br />
            <input
              required
              id="password"
              name="password"
              type="password"
              className="inputText"
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="off"
            />
            <span className="floating-label">Пароль</span>
          </div>
          <div className="auth-choose">
            <div className="auth-remember">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                onClick={handleClick}
                autoComplete="off"
              />
              <label>Запамʼятати мене</label>
            </div>
            <div>
              <Link className="forgot-password" to="/forgot-password">
                Забули пароль?
              </Link>
            </div>
          </div>
          <button className="auth-submit" type="submit">
            Продовжити
          </button>
          <div className="other-options">
            <div className="register-account">
              <span className="register-account-title">Немає акаунту?</span>
            </div>
            <Link className="register-account-link" to={ROUTES.REGISTER}>
              Реєстрація
            </Link>
          </div>
        </form>
      </Auth>
    </>
  );
};

export default Login;
