import React, { Component, useEffect } from "react";
import Select, { components } from "react-select";
import useWindowDimensions from '../../hooks/useWindowDimensions';
import axios from 'axios';

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const CityTo = (props) => {
  const { height, width } = useWindowDimensions();

  const city_to_options = localStorage.getItem('city-to-options');
  const localCityOptions = city_to_options !== 'undefined' ? JSON.parse(city_to_options) : [];
  let index = localCityOptions ? localCityOptions.findIndex(x => x.id == props.cityToId) : null;

  const [options, setOptions] = React.useState(localCityOptions ?? []);
  const [location, setLocation] = React.useState('');

  function handleChange(e) {
    props.setCityToId(e.id);
  }

  const token = process.env.REACT_APP_API_TOKEN;
  const domain = process.env.REACT_APP_DOMAIN;
  const url = `${domain}/api/bus4trip/city-to?lang=uk&from_id=${props.cityFromId}`;

  useEffect(() => {
    const fetchData = async () => {
      if (props.cityFromId != null) {
        try {
          const response = await axios.get(url); // Замените URL на свой
          localStorage.setItem('city-to-options', JSON.stringify(response.data));
          setOptions(response.data);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, [props.cityFromId]);

  function handleInputChange(inputValue) {
    const replacement = {
      q: "й",
      w: "ц",
      e: "у",
      r: "к",
      t: "е",
      y: "н",
      u: "г",
      i: "ш",
      o: "щ",
      p: "з",
      a: "ф",
      s: "і",
      d: "в",
      f: "а",
      g: "п",
      h: "р",
      j: "о",
      k: "л",
      l: "д",
      z: "я",
      x: "ч",
      c: "с",
      v: "м",
      b: "и",
      n: "т",
      m: "ь",
      Q: "Й",
      W: "Ц",
      E: "У",
      R: "К",
      T: "Е",
      Y: "Н",
      U: "Г",
      I: "Ш",
      O: "Щ",
      P: "З",
      A: "Ф",
      S: "і",
      D: "В",
      F: "А",
      G: "П",
      H: "Р",
      J: "О",
      K: "Л",
      L: "Д",
      Z: "Я",
      X: "Ч",
      C: "С",
      V: "М",
      B: "И",
      N: "Т",
      M: "Ь",
    };
  
    let convertedText = '';
    convertedText = inputValue.replace(/[A-Za-z]/g, function (match) {
      return replacement[match] || match;
    });

    convertedText = convertedText.replace(/\[/g, "х");
    convertedText = convertedText.replace(/\]/g, "ї");
    convertedText = convertedText.replace(/\;/g, "ж");
    convertedText = convertedText.replace(/\'/g, "є");
    convertedText = convertedText.replace(/\,/g, "б");
    convertedText = convertedText.replace(/\./g, "ю");
    convertedText = convertedText.replace(/\{/g, "Х");
    convertedText = convertedText.replace(/\}/g, "І");
    convertedText = convertedText.replace(/\:/g, "Ж");
    convertedText = convertedText.replace(/\"/g, "Є");
    convertedText = convertedText.replace(/\</g, "Б");
    convertedText = convertedText.replace(/\>/g, "Ю");
  
    setLocation(convertedText);
  }

  let customStyles = {};
  if (width > 525) {
    customStyles = {
      option: (provided) => ({
        color: "#000000",
        padding: 5,
      }),
      control: (base, state) => ({
        ...base,
        boxShadow: "none",
        border: "none",
        with: "100%",
      }),
      menu: (base) => ({
        ...base,
        width: "max-content",
        minWidth: "100%",
        marginTop: "16px",
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        minWidth: "444px"
      }),
      singleValue: (provided) => ({
        ...provided,
        color: "black",
        fontFamily: "OnestRegular",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "23px",
        lineHeight: "29px",
        color: "#000000",
        paddingTop: "10px",
      }),
      container: (provided, state) => ({
        ...provided,
        marginTop: 10,
        width: "100%",
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        overflow: "visible",
      }),
      placeholder: (provided, state) => ({
        ...provided,
        position: "absolute",
        top: state.hasValue || state.selectProps.inputValue ? -18 : "0%",
        transition: "top 0.1s, font-size 0.1s",
        fontSize: (state.hasValue || state.selectProps.inputValue) && 16,
        lineHeight: "35px"
      }),
    };
  } else {
    customStyles = {
      option: (provided) => ({
        color: "#000000",
        padding: 5,
      }),
      control: (base, state) => ({
        ...base,
        boxShadow: "none",
        border: "none",
        with: "100%",
      }),
      menu: (base) => ({
        ...base,
        width: "max-content",
        width: "100%",
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: "black",
        fontFamily: "OnestRegular",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "23px",
        lineHeight: "29px",
        color: "#000000",
        paddingTop: "10px",
      }),
      container: (provided, state) => ({
        ...provided,
        marginTop: 10,
        width: "100%",
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        overflow: "visible",
        padding: "0px",
      }),
      placeholder: (provided, state) => ({
        ...provided,
        position: "absolute",
        top: state.hasValue || state.selectProps.inputValue ? -18 : "0%",
        transition: "top 0.1s, font-size 0.1s",
        fontSize: (state.hasValue || state.selectProps.inputValue) && 12,
        lineHeight: "33px"
      }),
    };
  }

  const customFilterOption = (option, rawInput) => {
    const inputValue = rawInput.toLowerCase().trim();
    const optionLabel = option.label.toLowerCase();
    
    return optionLabel.startsWith(inputValue);
  };

  return (
    <Select
      classNamePrefix="select"
      name="city-to"
      components={{
        ValueContainer: CustomValueContainer,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      onInputChange={handleInputChange}
      defaultValue={options[index]}
      inputValue={location}
      placeholder="Куди"
      onChange={handleChange}
      getOptionLabel={(option) => {
        return option.title;
      }}
      getOptionValue={(option) => {
        return option.id;
      }}
      options={options}
      styles={customStyles}
      noOptionsMessage={() => null}
      filterOption={customFilterOption}
    />
  );
}

export default CityTo;
