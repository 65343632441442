import "./CheckoutBuyer.css";

const CheckoutBuyer = ({ email, setEmail, phone, setPhone, note, setNote }) => {
  
  const changeEmail = (event) => {
    setEmail(event.target.value);
  }

  const changePhone = (event) => {
    const regex = /[^\d+()\-\s]/g;;
    const inputValue = event.target.value;
    const cleanedValue = inputValue.replace(regex, '');

    setPhone(cleanedValue);
  }

  const changeNote = (event) => {
    setNote(event.target.value);
  }

  return (
    <>
      <div className="checkout-buyer-block">
        <div className="checkout-buyer-item-title">Інформація про покупця</div>
        <div className="checkout-buyer-item-contact">
          <div className="checkout-input-wraper">
            <br />
            <input
              required
              name="email"
              className="checkout-input-field checkout-buyer-item-contact-email"
              autoComplete="off"
              value={email}
              onChange={changeEmail}
            />
            <span className="checkout-input-label">Email</span>
          </div>
          <div className="checkout-input-wraper">
            <br />
            <input
              required
              name="tel"
              className="checkout-input-field checkout-buyer-item-contact-phone"
              autoComplete="off"
              value={phone}
              onChange={changePhone}
            />
            <span className="checkout-input-label">Телефон</span>
          </div>
        </div>
        <div className="checkout-buyer-item-note">
          <div className="checkout-input-wraper">
            <br />
            <input
              required
              name="description"
              className="checkout-input-field checkout-buyer-item-input-note"
              autoComplete="off"
              value={note}
              onChange={changeNote}
            />
            <span className="checkout-input-label">Примітка</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutBuyer;
