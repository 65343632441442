export const TicketFilter = () => {
  return (
    <>
      <div className="ticket-sort">
        <span className="ticket-sort-departure">Відправлення</span>
        <span className="ticket-sort-in-the-way">В дорозі</span>
        <span className="ticket-sort-arrival">Прибуття</span>
        <span className="ticket-sort-free-places">Вільних мість</span>
        <span className="ticket-sort-price">Ціна</span>
      </div>
    </>
  );
};

export default TicketFilter;
