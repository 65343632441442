import "./PageTitle.css";

export const PageTitle = ({ title }) => {
  return (
    <div className="page-head">
      <div className="page-title-block">
        <h1 className="page-title">{title}</h1>
      </div>
    </div>
  );
};

export default PageTitle;
