import BusSchemeSeat from "./BusSchemeSeat.jsx";
import { nanoid } from "nanoid";

const BusSchemeRow = ({ row, chooseSeats, handleChooseSeat }) => {


  return (
    <>
      <div className="seats-row">
        {row &&
          row.map((seat, index) => {
            if (seat)
              return <BusSchemeSeat seat={seat} key={nanoid()} chooseSeats={chooseSeats} handleChooseSeat={handleChooseSeat}/>
            else 
              return <div className="bus-space"  key={nanoid()}></div>
          })}
      </div>
    </>
  );
};

export default BusSchemeRow;
