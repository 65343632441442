import { useLocation } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../pages/utils/routes";
import RoadMap from "../RoadMap/RoadMap.jsx";
import { useEffect, useState } from "react";
import { nanoid } from "nanoid";

export const TicketItem = ({ ticket, search }) => {
  const navigate = useNavigate();
  const [openDetail, setOpenDetail] = useState(false);
  const [imageDetail, setImageDetail] = useState("");
  const [controllerLine, setControllerLine] = useState({});
  const [animation, setAnimation] = useState({
    display: "none",
  });
  const startPoint = ticket?.route?.find((point) => point.type === "start");
  const finalPoint = ticket?.route?.find((point) => point.type === "final");
  const searchPathname = useLocation().pathname;
  const searchParams = useLocation().search;
   console.log("departureTime:", ticket);
  useEffect(() => {
    if (openDetail === true) {
      setImageDetail("./image/svg/hidden.svg");
    } else {
      setImageDetail("./image/svg/more.svg");
    }
  }, [openDetail]);

  const displayMore = () => {
    setOpenDetail(!openDetail);
    setAnimation({
      display: !openDetail ? "block" : "none",
    });
    if (openDetail === false) {
      setControllerLine({
        paddingBottom: "30px",
        borderBottom: "1px solid #DDDEE5",
      });
    } else {
      setControllerLine({
        paddingBottom: "0px",
        borderBottom: "0px solid #DDDEE5",
      });
    }
  };

  const handleCheckout = () => {
    let currentSearchQuery = `${searchPathname}${searchParams}`;
    sessionStorage.setItem("currentSearchQuery", currentSearchQuery);
    const checkout = `/checkout/${ticket.id}?lang=${search.lang}&date=${search.date}&passengers=${search.passengers}&aggregator=${ticket.aggregator}&city-from=${search["city-from"]}&city-to=${search["city-to"]}`;
    sessionStorage.setItem("currentCheckoutLink", checkout);
    sessionStorage.setItem(
      `reserve-ticket-${ticket.id}`,
      JSON.stringify(ticket)
    );

    let url = null;
    if (ticket.bus_scheme === true) {
      url = `${ROUTES.SEATS}?lang=${search.lang}&trip_id=${ticket.id}&date=${search.date}&passengers=${search.passengers}`;
    } else {
      url = checkout;
    }
    window.scrollTo(0, 0); 
    navigate(url);
  };

  return (
    <>
      <div className="ticket-item">
        <div className="ticket-head-info">
          <div className="ticket-time">
            <div className="ticket-time-departure">
              <span className="ticket-time-departure-time">
                {ticket?.departure_time}
              </span>
              <span className="ticket-time-departure-date">
                {ticket?.departure_date}
              </span>
            </div>
            <div className="ticket-time-in-the-way">
              <div className="ticket-time-in-the-way-block">
                <img
                  className="ticket-time-in-the-way-image"
                  src="./image/svg/time.svg"
                  alt=""
                />
                <span className="ticket-time-in-the-way-info">
                  {ticket?.travel_time}
                </span>
              </div>
              <div className="ticket-time-in-the-way-type-mobile">
                <span>прямий рейс</span>
              </div>
            </div>
            <div className="ticket-time-arrival">
              <span className="ticket-time-arrival-time">
                {ticket?.arrival_time}
              </span>
              <span className="ticket-time-arrival-date">
                {ticket?.arrival_date}
              </span>
            </div>
            <div className="ticket-proposal ticket-proposal-desktop">
              <div className="ticket-proposal-place">
                <img
                  className="ticket-proposal-place-image"
                  src="./image/svg/place.svg"
                  alt=""
                />
                <span className="ticket-proposal-place-value">
                  {ticket?.seats}
                </span>
              </div>
              <div className="ticket-proposal-price">
                <span className="ticket-proposal-price-value">
                  {ticket?.price} ₴
                </span>
              </div>
            </div>
          </div>
          <div className="ticket-short-map">
            <div className="ticket-time-departure-point">
              {startPoint?.name}
            </div>
            {/* <div className="ticket-time-in-the-way-type-mobile">
              <span>прямий рейс</span>
            </div> */}
            <div className="ticket-time-without-bus-change">
              <span>прямий рейс</span>
            </div>
            {/* <div className="ticket-time-transplantation-point">
              <span>Пересадка Львів АВ</span>
              <span className="ticket-time-transplantation-point-duration">
                очікування 45 хв
              </span>
            </div> */}
            <div className="ticket-time-arrival-point">{finalPoint?.name}</div>
          </div>
          <div className="ticket-proposal ticket-proposal-mobile">
            <div className="ticket-proposal-place">
              <span className="ticket-proposal-mobile-title">
                Вільних місць:
              </span>
              <span className="ticket-proposal-place-value">
                {ticket?.seats}
              </span>
            </div>
            <div className="ticket-proposal-price">
              <span className="ticket-proposal-mobile-title">Ціна:</span>
              <span className="ticket-proposal-price-value">
                {ticket?.price} ₴
              </span>
            </div>
          </div>
        </div>
        <div className="ticket-controller" style={controllerLine}>
          <button className="ticket-controller-more-info" onClick={displayMore}>
            <span className="ticket-controller-more-title">
              Детальна інформація
            </span>
            <img
              className="ticket-controller-more-image"
              src={imageDetail}
              alt=""
            />
          </button>
          <button
            className="ticket-controller-checkout"
            onClick={handleCheckout}
          >
            Бронювати
          </button>
        </div>
        <div className="ticket-flight" style={animation}>
          <span>Рейс: </span>
          <span>Полтава АС-1-Варшава АВ Заходня</span>
        </div>
        <div className="ticket-info" style={animation}>
          <div className="ticket-chpter-and-road-map">
            <div className="chapter">
              <div className="ticket-info-chapter ticket-info-carrier">
                <span className="ticket-info-title">Перевізник:</span>
                <span className="ticket-info-value">
                  {ticket?.carriers.name}
                </span>
              </div>
              <div className="ticket-info-chapter ticket-info-bus">
                <span className="ticket-info-title">Автобус:</span>
                <span className="ticket-info-value">
                  {ticket?.vehicles &&
                    ticket?.vehicles.capacity &&
                    `Далекого сполучення (${ticket?.vehicles?.capacity})`}
                </span>
              </div>
              {ticket?.baggage && (
                <div className="ticket-info-chapter ticket-info-baggage">
                  <span className="ticket-info-title">Багаж:</span>
                  <div className="ticket-info-list ticket-info-baggage-list">
                    <span className="ticket-info-value ticket-info-baggage-item">
                      1 одиниця до 20 кг (30x45x60) –безкоштовно
                    </span>
                    <span className="ticket-info-value ticket-info-baggage-item">
                      1 ручна поклажа до 5 кг -безкоштовно
                    </span>
                  </div>
                </div>
              )}
              {ticket?.refund_conditions && (
                <div className="ticket-info-chapter ticket-info-return">
                  <span className="ticket-info-title">
                    Умови повернення квитка:
                  </span>
                  <div className="ticket-info-list ticket-info-return-list">
                    {ticket.refund_conditions.map((rules) => {
                      return (
                        <span
                          key={nanoid()}
                          className="ticket-info-value ticket-info-return-item"
                        >
                          {rules}
                        </span>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className="ticket-service-desktop">
                <div className="ticket-service-title">Доступні сервіси:</div>
                <div className="ticket-service-list">
                  {ticket.bus_options &&
                    ticket.bus_options.map((service) => {
                      return (
                        <div key={nanoid()} className="ticket-service-item">
                          <img
                            className="ticket-service-item-image"
                            src={service.image}
                            alt={service.description}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            {ticket?.route && <RoadMap road={ticket.route} departureTime={ticket.departure_time} />}
          </div>
          <div className="ticket-service-mobile">
            <div className="ticket-service-title">Доступні сервіси:</div>
            <div className="ticket-service-list">
              {ticket.bus_options &&
                ticket.bus_options.map((service) => {
                  return (
                    <div key={nanoid()} className="ticket-service-item">
                      <img
                        className="ticket-service-item-image"
                        src={service.image}
                        alt={service.description}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketItem;
