import PageTitle from "../../components/PageTitle/PageTitle";
import { Link, useParams, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import swal from "sweetalert";
import { ROUTES } from "../utils/routes";
import Auth from "../../layout/Auth/Auth.js";

async function resetPassword(credentials) {
  const token = process.env.REACT_APP_API_TOKEN;
  const domain = process.env.REACT_APP_DOMAIN;
  const url = `${domain}/api/auth/reset-password`;
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export const ResetPassword = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [password_confirmation, setPasswordConfirmation] = useState();
  const navigate = useNavigate();

  const { token } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await resetPassword({
      email,
      password,
      password_confirmation,
      token,
    });

    swal(
      "Проль оновлено",
      "Тепер можна спробувати зайти в система",
      "success",
      {
        buttons: false,
        timer: 20000,
      }
    ).then((value) => {
      navigate("/login");
    });
  };

  return (
    <>
      <PageTitle title={"Відновлення пароля"} />
      <Auth>
        <form className="auth-form" onSubmit={handleSubmit}>
          <div className="auth-controller">
            <label>Email</label>
            <input
              required
              id="email"
              name="email"
              label="Email Address"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="auth-controller">
            <label>Пароль</label>
            <input
              required
              id="password"
              name="password"
              label="Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="auth-controller">
            <label>Підтвердити пароль</label>
            <input
              required
              id="password_confirmation"
              name="password_confirmation"
              label="Password confirmation"
              type="password"
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
          </div>
          <button className="auth-submit" type="submit">
            Продовжити
          </button>
        </form>
        <div className="auth-redirect-section">
          <button className="auth-redirect-button">
            <Link to={ROUTES.LOGIN}>Згадали пароль? Увійти.</Link>
          </button>
          <button className="auth-redirect-button">
            <Link to={ROUTES.REGISTER}>
              Немає облікового запису? Зареєструвати.
            </Link>
          </button>
        </div>
      </Auth>
    </>
  );
};

export default ResetPassword;
