import { ROUTES } from "../utils/routes";
import "./Error_404.css";
import { Link } from "react-router-dom";

export const Error404 = () => {
  return (
    <>
      <div className="page-head">
        <div className="error-404-block">
          <img className="error-404-image" src="./image/svg/404.svg" alt="" />
          <span className="error-404-title">Сторінку не знайдено</span>
          <Link className="error-404-link-return" to={ROUTES.HOME}>
            <button className="error-404-button">
              <span className="error-404-button-title">
                Повернутись на головну
              </span>
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Error404;
