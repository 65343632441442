const UserAccountPassengersTableMobile = () => {
  return (
    <>
      <div className="user-table-mobile-content-row">
        <div className="user-table-mobile-content-row-value">
          <div className="passenger-table-mobile-cell-1">
            <div className="passenger-table-mobile-cell-title">Ім’я / Прізвище</div>
            <div className="passenger-table-mobile-cell-value">Джеймс Бонд</div>
          </div>
          <div className="passenger-table-mobile-cell-2">
            <div className="passenger-table-mobile-cell-title">Дата народження</div>
            <div className="passenger-table-mobile-cell-value">23.01.67</div>
          </div>
        </div>
        <div className="user-table-mobile-content-row-value user-table-mobile-content-row-value-second">
          <div className="passenger-table-mobile-cell-1">
            <div className="passenger-table-mobile-cell-title">Email</div>
            <div className="passenger-table-mobile-cell-value">bondjameslongemail@gmail.com</div>
          </div>
        </div>
        <div className="user-table-mobile-content-row-value user-table-mobile-content-row-value-second">
          <div className="passenger-table-mobile-cell-1">
            <div className="passenger-table-mobile-cell-title">Телефон</div>
            <div className="passenger-table-mobile-cell-value">+38 (093) 123 23 12</div>
          </div>
          <div className="passenger-table-mobile-cell-2">
            <div className="passenger-table-mobile-cell-title">Серія та номер док.</div>
            <div className="passenger-table-mobile-cell-value">HM2345JE</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAccountPassengersTableMobile;
