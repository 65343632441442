import { Link } from "react-router-dom";
import { ROUTES } from "../../pages/utils/routes";

export const Header = () => {
  const token = localStorage.getItem("token");
  let redirect = "/login";
  let userAvatar = "/image/svg/unknown-user.svg";

  if (token) redirect = "/user-account/orders";
  if (token) userAvatar = "/image/users/user.svg";

  return (
    <>
      <div className="header-container">
        <div className="header">
          <div className="company-logo">
            <Link to={ROUTES.HOME}>
              <div className="logo">
                <img src="/image/svg/logo.svg" />
              </div>
            </Link>
            <div className="logo-text">
              <div className="company-title">bus4trip</div>
              <div className="company-slogan">Твій автобус для подорожі</div>
            </div>
          </div>
          <div className="company-business-card">
            <div className="business-card-desktop">
              <span className="business-card-title">
                Замовляйте квитки по телефону
              </span>
              <span className="business-card-number">
                <a
                  className="business-card-number-link"
                  href="tel:+380973191904"
                >
                  +38 (097) 319-19-04
                </a>
              </span>
            </div>
            <div className="business-card-user">
              <Link to={redirect}>
                <img src={userAvatar} />
              </Link>
            </div>
          </div>
        </div>
        <div className="business-card-mobile">
          <span className="business-card-title">
            Замовляйте квитки по телефону
          </span>
          <span className="business-card-number">
            <a className="business-card-number-link" href="tel:+380973191904">
              +38 (097) 319-19-04
            </a>
          </span>
        </div>
      </div>
    </>
  );
};
