import React from "react";

export const Logout = () => {
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    window.location.href = "/login";
  };

  return (
    <>
      <button onClick={handleLogout} className="user-settings-logout">
        Logout
      </button>
    </>
  );
};

export default Logout;
