import { useLocation } from "react-router-dom";

export const OurPrivileges = () => {
  const location = useLocation();
  const page = location.pathname;
  let block = {};

  if (page === "/search") {
    block = { display: "none" };
  }
  return (
    <div className="company-goals" style={block}>
      <div className="company-goals-row">
        <div className="company-goals-card company-goals-trip goals-card-image-mobile">
          <div className="goals-card-image goals-card-image-trip-mobile">
            <img src="./image/img/countries_img.svg" />
          </div>
          <span className="goals-card-title goals-card-title-bottom goals-card-title-trip-mobile">
            Рейси в Європу та по Україні
          </span>
        </div>
        <div className="company-goals-card company-goals-bonus">
          <span className="goals-card-title goals-card-title-top">
            Отримуй бонуси за поїздки
          </span>
          <div className="goals-card-image">
            <img src="./image/img/bonus_img.svg" />
          </div>
        </div>
      </div>
      <div className="company-goals-row">
        <div className="company-goals-card company-goals-ticket goals-card-image-mobile">
          <div className="goals-card-image">
            <img
              src="./image/img/tickets_img.svg"
              className="company-goals-ticket-image"
            />
          </div>
          <span className="goals-card-title goals-card-title-bottom">
            Легко передавай квитки з особистого кабінету
          </span>
        </div>
        <div className="company-goals-card company-goals-reviews">
          <span className="goals-card-title goals-card-title-top">
            Ми відбираємо перевізників з найкращою репутацією
          </span>
          <div className="goals-card-image">
            <img src="./image/img/drivers_img.svg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurPrivileges;
