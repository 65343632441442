import React, { useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import "./Partners.css";
const Partners = () => {
  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('+380');

  const handleForm = async () => {
    const credentials = {
      title: title,
      name: name,
      phone: phone,
      email: email,
    };

    let token = process.env.REACT_APP_API_TOKEN;
    const domain = process.env.REACT_APP_DOMAIN;
    const url = `${domain}/api/bus4trip/partner`;
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(credentials),
    });

  };

  const changePhone = (event) => {
    const regex = /[^\d+()\-\s]/g;
    const inputValue = event.target.value;
    const cleanedValue = inputValue.replace(regex, "");

    setPhone(cleanedValue);
  };

  const changeName = (event) => {
    const regex = /[^a-zA-Zа-яА-ЯіІїЇєЄёЁ\s]/g; // Регулярное выражение
    const inputValue = event.target.value;
    const cleanedValue = inputValue.replace(regex, '');
    setName(cleanedValue);
  };

  return (
    <>
      <PageTitle title={"Перевізникам"} />
      <div className="page-container">
        <div className="carriers-block">
          <div className="carriers-info">
            <div className="carriers-title">
              <span>
              Запрошуємо автотранспортні підприємства до співпраці з продажу автобусних квитків на сайті BUS4TRIP. 
              Наша компанія є офіційним та ексклюзивним представником Distribusion в Україні - це технологічна платформа,
              що забезпечує доступ до наземного транспорту по всьому світу.
              Distribusion – це перший глобальний API бронювання B2B та найбільша мережа перевізників та роздрібних торговців у галузі
              </span>
            </div>
            <div className="carriers-steps">
              <span className="carriers-steps-title">
                У вас з’явиться можливість:
              </span>
              <div className="carriers-steps-list">
                <div className="carriers-steps-item">
                  <img
                    className="carriers-steps-item-image"
                    src="./image/svg/check-done.svg"
                    alt="check-done"
                  />
                  <span className="carriers-steps-item-value">
                    Збільшити обсяг продажів
                  </span>
                </div>
                <div className="carriers-steps-item">
                  <img
                    className="carriers-steps-item-image"
                    src="./image/svg/check-done.svg"
                    alt="check-done"
                  />
                  <span className="carriers-steps-item-value">
                    Зменшити витрати на касове обслуговування
                  </span>
                </div>
                <div className="carriers-steps-item">
                  <img
                    className="carriers-steps-item-image"
                    src="./image/svg/check-done.svg"
                    alt="check-done"
                  />
                  <span className="carriers-steps-item-value">
                    Вести віддалене адміністрування
                  </span>
                </div>
                <div className="carriers-steps-item">
                  <img
                    className="carriers-steps-item-image"
                    src="./image/svg/check-done.svg"
                    alt="check-done"
                  />
                  <span className="carriers-steps-item-value">
                    Керувати пасажиропотоком
                  </span>
                </div>
                <div className="carriers-steps-item">
                  <img
                    className="carriers-steps-item-image"
                    src="./image/svg/check-done.svg"
                    alt="check-done"
                  />
                  <span className="carriers-steps-item-value">
                    Аналізувати і переглядати звітність
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="carriers-form-block">
            <span className="carriers-form-title">Зв’яжіться з нами</span>
            <form className="carriers-form-main" action="#">
              <div className="carriers-form-fields">
                <div className="user-input-wrp">
                  <br />
                  <input
                    required
                    id="title"
                    name="title"
                    className="inputText"
                    onChange={(e) => setTitle(e.target.value)}
                    autoComplete="off"
                  />
                  <span className="floating-label">Назва компанії</span>
                </div>
                <div className="user-input-wrp">
                  <br />
                  <input
                    required
                    id="name"
                    name="name"
                    className="inputText"
                    value={name}
                    onChange={changeName}
                    autoComplete="off"
                  />
                  <span className="floating-label">Контактна особа</span>
                </div>
                <div className="user-input-wrp">
                  <br />
                  <input
                    required
                    id="email"
                    name="email"
                    className="inputText"
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="off"
                  />
                  <span className="floating-label">Email</span>
                </div>
                <div className="user-input-wrp">
                  <br />
                  <input
                    required
                    id="phone"
                    name="phone"
                    onChange={changePhone}
                    value={phone}
                    className="inputText"
                    autoComplete="off"
                  />
                  <span className="floating-label">Номер телефону</span>
                </div>
              </div>
              <button className="carriers-form-button" type="submit" onClick={handleForm} disabled={title && name && phone && email ? false : true}>
                Стати партнером
              </button>
            </form>
            <span className="carriers-form-personal-info">
              Надсилаючи заявку, я даю згоду на
              <a href="/offer" className="carriers-form-personal-info-link">
                обробку персональних данних.
              </a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Partners;
