import { useState } from "react";
import DatePicker from "react-datepicker";
import "./date-from.css"
import uk from 'date-fns/locale/uk';

function convertDate(date)
{
  date = date.split('.');
  const newDate = `${date[2]}-${date[1]}-${date[0]}`;

  return newDate;
}

export const DateForm = (props) => {
  const satartDate = convertDate(props.startDate);
  const [datePicker, setDatePicker] = useState(new Date(satartDate));

  return (
    <>
      <div>
        <span className="date-from-title">Дата туди</span><br />
        <DatePicker
          selected={datePicker}
          disableClock={true}
          onChange={(date) => {
            const d = new Date(date).toLocaleDateString("uk-Uk");
            props.setStartDate(d);
            setDatePicker(date);
          }}
          dateFormat="dd-MM-yyyy"
          locale={uk} 
          minDate={new Date()}
        />
      </div>
    </>
  );
};

export default DateForm;
