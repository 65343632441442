export const ROUTES = {
    HOME: '/',
    SEARCH: '/search',
    SEATS: '/seats',
    CHECKOUT: '/checkout/:ticketId',
    BOOKING: '/booking',
    REGISTER: '/register',
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    LOGOUT: '/logout',
    ACCOUNT: '/account/verify/:hash',
    VERIFY: '/verify/phone',
    USER_ACCOUNT: '/user-account',
    ORDERS:'orders',
    PASSENGERS: 'passengers',
    SETTINGS: 'settings',
    RESET_TOKEN: '/reset-password/:token',
    OFFER: 'offer',
    CONFIDENCE: 'confidence',
    PARTNERS: 'partners',
    ERROR_404: '*'
}
