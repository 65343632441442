import { useEffect, useState } from "react";
import "./Step.css";

const Step = ({ type }) => {
  const [step, setStep] = useState();
  const [final, setFinal] = useState();

  useEffect(() => {
    if (type === "start") {
      setStep("step-line-start");
    } else if (type === "final") {
      setStep("step-line-final");
      setFinal("step-line-point-active");
    }
  }, [step]);
  return (
    <>
      <div className="step-block">
        <div className={`step-line ${step}`}>
          <span className="step-line-point step-line-point-active"></span>
          <span className={`step-line-point ${final}`}></span>
          <span className={`step-line-point ${final}`}></span>
        </div>
        <div className="step-line-title">
          <div className="step-line-title-first">
            <span className="step-line-ttile-active">Оформлення</span>
          </div>
          <div className="step-line-title-second">
            <span>Оплата</span>
          </div>
          <div className="step-line-title-third">
            <span>Готово</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step;
