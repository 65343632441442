import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Step from "../../components/Step/Step.jsx";
import Timer from "../../components/Timer/Timer.jsx";
import ReserveTicket from "../../components/ReserveTicket/ReserveTicket.jsx";
import CheckoutPassengerList from "../../components/CheckoutPassengerList/CheckoutPassengerList.jsx";
import CheckoutBuyer from "../../components/CheckoutBuyer/CheckoutBuyer.jsx";
import CheckoutInvoice from "../../components/CheckoutInvoice/CheckoutInvoice.jsx";
import useQueryParams from "../../hooks/useQueryParams.js";
import { ROUTES } from "../../pages/utils/routes";
import "./Checkout.css";

const Checkout = () => {
  const query = useQueryParams("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("+380");
  const [note, setNote] = useState("");
  const [promocode, setPromocode] = useState("");
  const [passengers, setPassengers] = useState([]);
  const [stepType, setStepType] = useState("start");
  const [sendForm, setSendForm] = useState(false);
  const { ticketId } = useParams();
  const [chooseSeats, setChooseSeats] = useState([]);
  const navigate = useNavigate();
  const [ticket, setTicket] = useState();
  const [checkouButtonStatus, setCheckouButtonStatus] = useState(true)
  const [liqPayRequest, setLiqPaRequest] = useState('')
  const [liqPayData, setLiqPayData] = useState('')
  const [liqPaySignature, setLiqPaySiliqPaySignature] = useState('')

  useEffect(() => {
    if (ticket === undefined)
      setTicket(
        JSON.parse(sessionStorage.getItem(`reserve-ticket-${ticketId}`))
      );
  });

  useEffect(() => {
    if (passengers === []) setPassengers(Array(query.passengers).fill({}));
  }, [passengers]);

  useEffect(() => {
    const sessionChooseSeats = JSON.parse(
      sessionStorage.getItem(`chooseSeats-${ticketId}`)
    );
    if (sessionChooseSeats && chooseSeats.length == 0) {
      setChooseSeats(sessionChooseSeats);
    } else if (chooseSeats.length == 0) {
      let tmp = [];
      for (let i = 0; i < query.passengers; i++) {
        const currentSeat = {
          id: null,
          seat: "#",
        };
        tmp.push(currentSeat);
      }
      setChooseSeats(tmp);
    }
  }, [chooseSeats]);

  useEffect(() => {
    console.log('length', passengers.length, chooseSeats.length, email && phone && passengers.length === chooseSeats.length)
    if (email !== '' && phone !== "+380" && passengers.length === chooseSeats.length) {
        setCheckouButtonStatus(false)
    }
  }, [email, phone, passengers])

  const handleForm = async () => {
    console.log('Active handleForm')
    setSendForm(true);
    const credentials = {
      phone: phone,
      email: email,
      note: note,
      promocode: promocode,
      passengers: passengers,
      ticket_id: ticketId,
      ticket: ticket,
      aggregator: query.aggregator,
      city_from_id: query["city-from"],
      city_to_id: query["city-to"],
      price: ticket?.price,
      departure_date: ticket?.departure_date,
    };
    console.log(credentials);

    let token = process.env.REACT_APP_API_TOKEN;
    const domain = process.env.REACT_APP_DOMAIN;
    const url = `${domain}/api/bus4trip/booking`;
    if (localStorage.getItem("token")) token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(credentials),
    }).then((data) => data.json())
    
    console.log("Liq pay", response)  
    if (response.data && response.signature) {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = 'https://www.liqpay.ua/api/3/checkout';
      
        for (const key in response) {
          if (response.hasOwnProperty(key)) {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = response[key];
            form.appendChild(input);
          }
        }
      
        document.body.appendChild(form);
        console.log(form)
        form.submit();
    }

    // Робоча частини для переадресування на сторінку Вітання
    // if (response.status === true) {
    //   navigate(ROUTES.BOOKING);
    // }
  };

  return (
    <>
      <div className="page-head">
        <Step type={stepType} />
      </div>
      <Timer ticket={ticket}/>
      <ReserveTicket ticket={ticket} />
      <div className="page-container">
        {chooseSeats && (
          <CheckoutPassengerList
            chooseSeats={chooseSeats}
            passengers={passengers}
            setPassengers={setPassengers}
          />
        )}
        <CheckoutBuyer
          email={email}
          setEmail={setEmail}
          phone={phone}
          setPhone={setPhone}
          note={note}
          setNote={setNote}
        />
        <CheckoutInvoice
          promocode={promocode}
          setPromocode={setPromocode}
          handleForm={handleForm}
          chooseSeats={chooseSeats}
          ticket={ticket}
          checkouButtonStatus={checkouButtonStatus}
          liqPayRequest={liqPayRequest}
          liqPayData={liqPayData}
          liqPaySignature={liqPaySignature}
        />
      </div>
    </>
  );
};

export default Checkout;
