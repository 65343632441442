import ReturnTicket from "../../../components/ReturnTicket/ReturnTicket.js";
import React, { useEffect, useState } from "react";

const UserAccountOrdersTableItemMobile = ({ item }) => {
  const [open, setOpen] = useState(false);

  const handleConfirm = (result) => {
    setOpen(false);
  };

  return (
    <div className="user-table-mobile-content-row">
      <div className="user-table-mobile-content-row-value">
        <div className="order-table-mobile-cell-1">
          <div className="order-table-mobile-cell-title">№ замовлення</div>
          <div className="order-table-mobile-cell-value">{item?.id}</div>
        </div>
        <div className="order-table-mobile-cell-2">
          <div className="order-table-mobile-cell-title">Звідки</div>
          <div className="order-table-mobile-cell-value">{item?.city_from}</div>
        </div>
        <div className="order-table-mobile-cell-3">
          <div className="order-table-mobile-cell-title">Куди</div>
          <div className="order-table-mobile-cell-value">{item?.city_to}</div>
        </div>
      </div>
      <div className="user-table-mobile-content-row-value user-table-mobile-content-row-value-second">
        <div className="order-table-mobile-cell-1">
          <div className="order-table-mobile-cell-title">Дата замовлення</div>
          <div className="order-table-mobile-cell-value">
            {item?.create_date}
          </div>
        </div>
        <div className="order-table-mobile-cell-2">
          <div className="order-table-mobile-cell-title">Телефон</div>
          <div className="order-table-mobile-cell-value">{item?.phone}</div>
        </div>
      </div>
      <div className="user-table-mobile-content-row-value user-table-mobile-content-row-value-third">
        <div className="order-table-mobile-cell-3">
          <div className="order-table-mobile-cell-title">Email</div>
          <div className="order-table-mobile-cell-value">{item?.email}</div>
        </div>
      </div>
      {/* <div className="user-table-mobile-content-row-value user-table-mobile-content-row-value-third">
        <div className="order-table-mobile-cell-button">
          <img className="order-table-mobile-cell-button-image" src="/image/svg/send-email.svg" alt="not-found" />
          <div className="order-table-mobile-cell-button-title">Надіслати на пошту</div>
        </div>
        <div className="order-table-mobile-cell-image order-table-mobile-cell-image-return">
          <div className="order-table-mobile-cell-button">
            <img className="order-table-mobile-cell-button-image" src="/image/svg/return-ticket.svg" alt="not-found" onClick={() => setOpen(true)} />
            <div className="order-table-mobile-cell-button-title">Повернути квиток</div>
          </div>
        </div>
      </div> */}
      <ReturnTicket open={open} handleConfirm={handleConfirm} />
    </div>
  );
};

export default UserAccountOrdersTableItemMobile;
