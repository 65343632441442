import CheckoutPassenger from "../../components/CheckoutPassenger/CheckoutPassenger.jsx";
import { nanoid } from "nanoid";

const CheckoutPassengerList = ({ chooseSeats, passengers, setPassengers }) => {
  return (
    <>
      <div className="checkout-passenger-block">
        <div className="checkout-passenger-list">
            {chooseSeats && chooseSeats.map((seat, index) => <CheckoutPassenger key={index} seat={seat} passengers={passengers} index={index} setPassengers={setPassengers} />)}
        </div>
      </div>
    </>
  );
};

export default CheckoutPassengerList;
