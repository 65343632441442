import React from "react";
import OurPrivileges from "../../components/OurPrivileges/OurPrivileges.js";
import SearchForm from "../../components/SearchForm/SearchForm.jsx";
import { Outlet } from "react-router-dom";

export const MainPage = (setNewSearch) => {
  return (
    <>
      <div className="page-head">
        <SearchForm setNewSearch={setNewSearch} />
      </div>
      <div className="page-container">
        <Outlet />
        <OurPrivileges />
      </div>
    </>
  );
};

export default MainPage;
